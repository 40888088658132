import { find, isEmpty, isNumber, toNumber } from 'lodash'
import { functions } from '../../config/firebase'
import { formatDateAsDayMonthYear } from '../../utils/date/dateFormat'
import getDoc from '../../utils/db/getDoc'
import promiseAllValues from '../../utils/promiseAllValues'
import getCollection from '../../utils/db/getCollection'

function sendOrderEmail(data) {
  const func = functions.httpsCallable('emailSender')

  data.delDate = { seconds: data.delDate / 1000 }
  data.retDate = { seconds: data.retDate / 1000 }

  const delTotals =
    toNumber(data.counters.delivery) + toNumber(data.counters.return)

  return promiseAllValues({
    car: getDoc({ path: 'cars', docId: data.carId }),
    options: getCollection({ path: 'options', docIdName: 'optionId' }),
  }).then((result) => {
    const car = `${result.car.title} ${result.car.modelYear} ${data.transmission}КПП`
    let parsedOptions = ''

    if (isEmpty(data.additionalOptions)) {
      parsedOptions = 'Опции не выбраны<br/>'
    } else {
      data.additionalOptions.forEach((o) => {
        const option = find(result.options, ['optionId', o])
        if (option) {
          const price = parseOptionPrice({
            option,
            fullDays: data.counters.fullDays,
          })

          parsedOptions =
            parsedOptions +
            `<b>- ${option.title_ru}</b>: ${
              isNumber(toNumber(price))
                ? price > 0
                  ? `${price} ₽`
                  : 'Бесплатно'
                : price
            }<br/>`
        }
      })
    }

    const html = `
  <!DOCTYPE html>
    <html>
    <body style="margin: 0px; font-family: Arial, Helvetica, sans-serif; background: #f7f7f7; text-align: center; line-height:1.5em; letter-spacing: .7px;">
    <div style="background: #f7f7f7; padding:20px 0px 30px 0px;">
      <div style="width:800px; height:100%; background:#fff; margin:0px auto;">

        <div style="font-family: 'HelveticaNeue', Sans-Serif; font-weight:bold; padding:20px 0px; text-align:center; font-size:18px;">
          Инфо о клиенте
        </div>

        <div style="font-family: 'HelveticaNeue', Sans-Serif; padding:0px 40px; width:470px; text-align:left; font-size:14px; line-height:24px; margin-bottom:20px;">
          <b>Фио</b>: ${data.userFio}<br/>
          <b>Дата рождения</b>: ${data.userBdate}<br/>
          <b>E-mail</b>: ${data.userEmail}<br/>
          <b>Контактный телефон</b>: ${
            data.userPhone.metadata.formatInternational
          }<br/>
          <b>Водительский стаж</b>: ${data.vodStage} лет<br/><br/>
          <b>Доп. комментарий:</b> ${data.userComment ? data.userComment : '-'}
        </div>

        <div style="font-family: 'HelveticaNeue', Sans-Serif; font-weight:bold; padding:20px 0px; text-align:center; font-size:18px;">
          Автомобиль, сроки аренды, расчет стоимости
        </div>

        <div style="font-family: 'HelveticaNeue', Sans-Serif; padding:0px 40px; width:470px; text-align:left; font-size:14px; line-height:24px; margin-bottom:20px;">
          <b>Автомобиль</b>: ${car}<br/>
          <b>Дата подачи</b>: ${formatDateAsDayMonthYear(data.delDate)} в ${
      data.delTime
    }<br/>
          <b>Место подачи</b>: ${parseLocationType(data.delType)} ${
      data.delType === 'other' ? `, ${data.delLocation}` : ''
    }<br/>
          <b>Дата возврата</b>: ${formatDateAsDayMonthYear(data.retDate)} в ${
      data.retTime
    }<br/>
          <b>Место возврата</b>: ${parseLocationType(data.retType)} ${
      data.retType === 'other' ? `, ${data.retLocation}` : ''
    }<br/><br/>

          <b>Срок аренды (суток)</b>: ${data.counters.fullDays}<br/><br/>
          <b>Тариф (за сутки)</b>: ${data.counters.rentPrice}<br/>
          <b>Стоимость доставки/возврата</b>: ${
            delTotals > 0 ? `${delTotals} ₽` : 'Бесплатно'
          }<br/>
          <b>Итоговая стоимость выбраных опций</b>: ${
            data.counters.options > 0
              ? `${data.counters.options} ₽`
              : 'Бесплатно'
          }<br/>
          <b>Стоимость залога</b>: ${data.counters.zalog} ₽<br/>
          <b>Итоговая стоимость с учётом залога и доп. опций</b>: ${countOrderPrice(
            data
          )} ₽<br/><br/>

          <b>Способ оплаты</b>: ${
            data.paymentType === 'cash'
              ? 'Наличными при получение'
              : 'Онлайн оплата картой'
          }'
        </div>

        <div style="font-family: 'HelveticaNeue', Sans-Serif; font-weight:bold; padding:20px 0px; text-align:center; font-size:18px;">
          Дополнительные опции
        </div>

        <div style="font-family: \'HelveticaNeue\', Sans-Serif; padding:0px 40px; width:470px; text-align:left; font-size:14px; line-height:24px; margin-bottom:20px;">
        ${parsedOptions}
        </div>

        <div style="font-family: 'HelveticaNeue', Sans-Serif; padding:0px 40px; width:470px; text-align:left; font-size:14px; line-height:24px; margin-bottom:20px;">
          Уважаемый ${
            data.userFio
          }, Вы забронировали автомобиль ( далее ТС) марки ${car}<br/><br/>

          ПРОСИМ ВАС ВНИМАТЕЛЬНО ОЗНАКОМИТЬСЯ С УСЛОВИЯМИ И ПРАВИЛАМИ
          АРЕНДЫ ТС НА НАШЕМ САЙТЕ!!! http://autoprokat51.ru/#client<br/><br/>

          ОБРАЩАЕМ ВАШЕ ВНИМАНИЕ, ЕСЛИ У ВАС ЕСТЬ ПЛАНЫ ВЫЕЗДА в пос.ТЕРИБЕРКА сообщите об этом. НЕ все ТС из нашего парка разрешено эксплуатировать в этом районе.<br/><br/>

          На полуостров РЫБАЧИЙ, СРЕДНИЙ, горный массив Хибины, пос. Дальние Зеленцы и другие поселения и места, куда проезд проходит по дорогам , не приспособленным для проезда неподготовленного ТС - ВЫЕЗД ЗАПРЕЩЁН!! Подробности уточняйте у Администратора АВТОПРОКАТ51.<br/><br/>

          Если у Вас возникнут вопросы или замечания, просим Вас сообщать о них ЗАБЛАГОВРЕМЕННО,
          и ДО НАЧАЛА АРЕНДЫ ТС!!!! Это позволит сэкономить Ваше время, а так же оперативно
          решить возникшие разногласия.<br/><br/>

          Начало аренды – ${formatDateAsDayMonthYear(data.delDate)} в ${
      data.delTime
    }<br/>
          Окончание аренды – ${formatDateAsDayMonthYear(data.retDate)} в ${
      data.retTime
    }<br/><br/>

          Место получения автомобиля - ${parseLocationType(data.delType)} ${
      data.delType === 'other' ? `, ${data.delLocation}` : ''
    }<br/>
          Место возврата автомобиля – ${parseLocationType(data.retType)} ${
      data.retType === 'other' ? `, ${data.retLocation}` : ''
    }<br/><br/>

          Расчет стоимости аренды ТС:<br/>
          • Тариф аренды ТС – ${data.counters.rentPrice} р/сут<br/>
          • Стоимость аренды – ${data.counters.rentPrice} * ${
      data.counters.fullDays
    } сут = ${data.counters.rentPrice * data.counters.fullDays} рублей<br/>
          • Подача ТС – ${data.counters.delivery} рублей<br/>
          • Возврат ТС – ${data.counters.return} рублей<br/>
          • Мойка ТС* = ${data.counters.carwash} рублей<br/>
          • Залог за ТС** = ${data.counters.zalog} рублей<br/><br/>

          Доп. опции:<br/>
          ${parsedOptions}

          Общая стоимость аренды ТС с учетом дополнительных услуг и залога = ${countOrderPrice(
            data
          )} рублей<br/><br/>

          * В случае возврата ТС в грязном виде арендатор оплачивает арендодателю стоимость
          услуги мойки ( от ${data.counters.carwash} р.)<br/><br/>

          ** ЗАЛОГ ЗА ТС ВНОСИТСЯ ИСКЛЮЧИТЕЛЬНО НАЛИЧНЫМИ ДЕНЕЖНЫМИ
          СРЕДСТВАМИ. ВОЗВРАТ ЗАЛОГА ЗА ТС ПРОИЗВОДИТСЯ ПО ФАКТУ
          ВОЗВРАТА ТС В ПАРК АРЕНДОДАТЕЛЯ, И ПЕРЕДАЕТСЯ ВАМ ТАК ЖЕ
          НАЛИЧНЫМИ ДЕНЕЖНЫМИ СРЕДСТВАМИ!!!!<br/><br/>

          СУТОЧНОЕ ОГРАНИЧЕНИЕ ПРОБЕГА ТС СОСТАВЛЯЕТ 350 КМ. В СУТКИ.<br/><br/>

          Если у вас будет перепробег - Оплачиваете его по факту сдачи ТС, из расчета 10 рублей за 1 км.<br/><br/>

          ВАШ ЛИМИТ – ${data.counters.fullDays * 350} КМ<br/><br/>

          По независящим от нас обстоятельствам возможна замена марки автомобиля. Мы максимально стараемся выполнить договоренности по данному бронированию. Предварительное бронирование не является договором проката.<br/><br/>

          ВНИМАНИЕ!!!<br/>
          ОБЯЗАТЕЛЬНО СООБЩИТЕ НАМ, ЕСЛИ У ВАС ИЗМЕНЯТСЯ ПЛАНЫ!!!<br/><br/>

          При необходимости, Вы можете связаться с нами:<br/>
          тел.: +7 (8152) 75-11-88 или +7 902-281-11-88<br/>
          e-mail: autoprokat51@mail.ru<br/>
          http://autoprokat51.ru
        </div>

        <style>
            th, td {
              padding: 4px
            }
        </style>

        <table border="1" style="margin: 0px auto 40px auto; border-collapse: collapse; text-align: left;">
            <thead>
                <tr>
                    <th colspan="2">Б. Арендатор:</th>
                    <th colspan="2">В. Автомобиль:</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>ФИО</td>
                    <td>${data.userFio}</td>
                    <td>Марка автомобиля</td>
                    <td>${car}</td>
                </tr>
                <tr>
                    <td>Адрес регистрации</td>
                    <td>${data.passportAddress}</td>
                    <td>Гос. регистрационный знак</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Адрес фактический</td>
                    <td>${data.userFact}</td>
                    <td>Идентифик-ый номер ()</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Телефон</td>
                    <td>${data.userPhone.metadata.formatInternational}</td>
                    <td>Модель, номер двигателя</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Дата рождения</td>
                    <td>${data.userBdate}</td>
                    <td>Номер кузова</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Паспорт</td>
                    <td>${data.passportSeria}</td>
                    <td>Год выпуска</td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td>${data.passportKem}, ${data.passportDate}</td>
                    <td>Цвет</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Вод. удостоверение</td>
                    <td>${data.vodSeria}</td>
                    <td>Примечание</td>
                    <td></td>
                </tr>
                <tr>
                    <td colspan="2" style="font-weight: bold">Дата, время, место выдачи: ${formatDateAsDayMonthYear(
                      data.delDate
                    )} в ${data.delTime} ${parseLocationType(data.delType)} ${
      data.delType === 'other' ? `, ${data.delLocation}` : ''
    }</td>
                    <td colspan="2" style="font-weight: bold">Дата, время, место возврата: ${formatDateAsDayMonthYear(
                      data.retDate
                    )} в ${data.retTime} ${parseLocationType(data.retType)} ${
      data.retType === 'other' ? `, ${data.retLocation}` : ''
    }</td>
                </tr>
            </tbody>
        </table>

      </div>
    </div>
    </body>
    </html>
  `

    return func({
      subject: `Бронирование автомобиля ${car}`,
      to: 'autoprokat51@mail.ru',
      html,
    })
  })
}

const parseLocationType = (location) => {
  switch (location) {
    case 'office':
      return 'Офис'
    case 'airport':
      return 'Аэропорт'
    default:
      return 'Свой адрес'
  }
}

function countOrderPrice(data) {
  const {
    carwash,
    delivery,
    fullDays,
    options,
    rentPrice,
    return: ret,
    zalog,
  } = data.counters

  return (
    toNumber(carwash) +
    fullDays * toNumber(rentPrice) +
    options +
    delivery +
    ret +
    toNumber(zalog)
  )
}

const parseOptionPrice = ({ option, fullDays }) => {
  switch (option.priceType) {
    case 'once':
      return option.price
    case 'dog':
      return 'Договорная'
    default:
      return toNumber(option.price) * fullDays
  }
}

export default sendOrderEmail
