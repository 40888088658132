import React, { useContext, useEffect } from 'react'
import locale from '../Top/LangChooser/locale/locale'
import { AuthContext } from '../../App'
import i1 from './assets/icons/boy.svg'
import i2 from './assets/icons/driver.svg'
import i3 from './assets/icons/passport.svg'
import './Client.sass'
import o1 from './assets/objects/ClientsCircle.png'
import { useState } from 'react'
import getCollection from '../../utils/db/getCollection'
import Icon from '../../ui/Icon/Icon'
import { isEmpty, orderBy } from 'lodash'
import parse from 'html-react-parser'
import toggleSpoiler from '../../utils/toggleSpoiler'

function Client() {
  const { lang } = useContext(AuthContext)
  const types = [
    {
      icon: i1,
      title: locale[lang].clientYears,
    },
    {
      icon: i2,
      title: locale[lang].clientPassport,
    },
    {
      icon: i3,
      title: locale[lang].clientDriverExperience,
    },
  ]

  const [rules, setRules] = useState(null)

  useEffect(() => {
    getCollection({
      path: 'rules',
      docIdName: 'ruleId',
      whereQueries: [{ fieldPath: 'isDeleted', op: '==', value: false }],
    }).then((data) => setRules(orderBy(data, ['position'], ['asc'])))
  }, [])

  return (
    <section
      id="client"
      name="client"
      class="Client"
      style={{ backgroundImage: `url(${o1})` }}
    >
      <div className="DefaultContainer">
        <p className="STitle">{locale[lang].clientTitle}</p>
        <div className="Client-Container">
          {types.map((b) => (
            <ClientTile key={`cIcon-${b.icon}`} {...b} />
          ))}
        </div>
        <div className="Rules-Container">
          {!isEmpty(rules) &&
            rules.map((r) => (
              <RuleTile
                title={r[`title_${lang.toLowerCase()}`]}
                description={r[`description_${lang.toLowerCase()}`]}
              />
            ))}
        </div>
      </div>
    </section>
  )
}

function ClientTile({ icon, title }) {
  return (
    <div class="ClientTile">
      <img src={icon} alt={title} />
      <p className="Title">{title}</p>
    </div>
  )
}

function RuleTile({ title, description }) {
  return (
    <div class="RuleTile">
      <div class="RuleTile-Header" onClick={toggleSpoiler}>
        <div class="Circle">
          <Icon name="angle-right" weight="solid" />
        </div>
        <p className="Title">{title}</p>
      </div>
      <div class="RuleTile-Body">{parse(description)}</div>
    </div>
  )
}

export default Client
