import React from 'react'
import './Button.sass'
import { NavLink } from 'react-router-dom'
import SpinnerWhite from './assets/spinner-white.svg'
import SpinnerGray from './assets/spinner-gray.svg'
import Icon from '../Icon/Icon'
import { NavHashLink } from 'react-router-hash-link'
import scrollWithOffset from '../ScrollToTop/scrollWithOffset'

const Button = React.forwardRef(
  (
    {
      id,
      title,
      type = 'button',
      theme = 'primary',
      size = 48,
      path,
      targetBlank = false,
      children,
      className,
      aClassName,
      onClick,
      isLoading,
      icon,
      iconPosition = 'left',
      iconWeight = 'light',
      style,
      tooltip,
      state = 'default',
      disabled = false,
    },
    ref
  ) => {
    let buttonTheme = ''

    switch (theme.toLowerCase()) {
      case 'primary':
        buttonTheme = 'Button_theme_primary'
        break
      case 'secondary':
        buttonTheme = 'Button_theme_secondary'
        break
      case 'internal-link':
        buttonTheme = 'Button_theme_internal-link'
        break
      default:
        buttonTheme = 'Button_theme_primary'
        break
    }
    const spinner = SpinnerWhite

    let fontClass = 'Button-Bold'

    switch (size) {
      case 48:
        fontClass = 'Button-Bold'
        break
      case 32:
      case 24:
        fontClass = 'Button-Medium'
        break
      default:
        fontClass = 'Button-Bold'
        break
    }

    const buttonComponent = (
      <button
        ref={ref}
        id={id}
        type={type}
        data-tip={tooltip}
        data-effect="solid"
        className={[
          'Button',
          ...(buttonTheme ? [buttonTheme] : []),
          ...(className ? [className] : []),
          ...(!title && !children ? ['Button_content_empty'] : []),
          ...(size ? [`Button_size_${size}`] : []),
          `Button-State_${state}`,
        ].join(' ')}
        onClick={disabled ? () => null : onClick}
        style={style}
        disabled={disabled}
      >
        <div
          className={[
            'Button-Content',
            ...(icon || isLoading
              ? [`Button_icon_position_${iconPosition}`]
              : []),
          ].join(' ')}
        >
          {(isLoading || icon) && (
            <span
              className={[
                'Button-IconContainer',
                ...(!title && !children ? ['Button_content_empty'] : []),
              ].join(' ')}
            >
              {isLoading ? (
                <img src={spinner} alt="Loading" />
              ) : (
                <Icon name={icon} weight={iconWeight} />
              )}
            </span>
          )}
          <span className={['Button-Child', fontClass].join(' ')}>
            {children || title}
          </span>
        </div>
      </button>
    )

    switch (type.toLowerCase()) {
      // case 'link':
      //   return (
      //     <a
      //       ref={ref}
      //       id={id}
      //       href={path}
      //       target={targetBlank ? 'blank' : ''}
      //       data-tip={tooltip}
      //       className={[
      //         'Button',
      //         ...(buttonTheme ? [buttonTheme] : []),
      //         ...(buttonColor ? [buttonColor] : []),
      //         ...(buttonFill ? [buttonFill] : []),
      //         ...(buttonBorder ? [buttonBorder] : []),
      //         ...(className ? [buttonTheme, className] : []),
      //         ...(icon || isLoading
      //           ? [`Button_icon_position_${iconPosition}`]
      //           : []),
      //         ...(size ? [`Button_size_${size}`] : []),
      //       ].join(' ')}
      //       style={style}
      //     >
      //       <div className="Button-Content">
      //         <div className="Button-Content">
      //           {(isLoading || icon) && (
      //             <span className="Button-IconContainer">
      //               {isLoading ? (
      //                 <img src={spinner} alt="Loading" />
      //               ) : (
      //                 <Icon name={icon} weight={iconWeight || 'regular'} />
      //               )}
      //             </span>
      //           )}
      //           <span data-tip={tooltip}>{children || title}</span>
      //         </div>
      //       </div>
      //     </a>
      //   )
      case 'navlink':
        return disabled ? (
          buttonComponent
        ) : (
          <NavLink ref={ref} to={path} className={aClassName}>
            {buttonComponent}
          </NavLink>
        )
      case 'navhashlink':
        return disabled ? (
          buttonComponent
        ) : (
          <NavHashLink
            ref={ref}
            to={path}
            className={aClassName}
            smooth
            scroll={(el) => scrollWithOffset(el, -64)}
          >
            {buttonComponent}
          </NavHashLink>
        )
      default:
        return buttonComponent
    }
  }
)

export default Button
