import React, { useContext } from 'react'
import './Footer.sass'
import Logo from '../../ui/Logo/Logo'
import EmailLink from '../Top/EmailLink/EmailLink'
import { NavLink } from 'react-router-dom'
import Icon from '../../ui/Icon/Icon'
import { HashLink, NavHashLink } from 'react-router-hash-link'
import scrollWithOffset from '../../ui/ScrollToTop/scrollWithOffset'
import PhoneChooser from '../Top/PhoneChooser/PhoneChooser'
import locale from '../Top/LangChooser/locale/locale'
import { AuthContext } from '../../App'
import Media from 'react-media'

function Footer() {
  const { lang } = useContext(AuthContext)
  return (
    <footer id="contacts" className="Footer">
      <div className="Main DefaultContainer">
        <Logo />
        <div className="ContactBlock">
          <PhoneChooser />
          <EmailLink />
        </div>
      </div>
      <div className="Line" />
      <div className="Footer-InfoContainer DefaultContainer">
        <div className="Clinic">
          <div className="LinkBlock">
            <HashLink
              to="/#catalog"
              smooth
              scroll={(el) => scrollWithOffset(el, -64)}
            >
              {locale[lang].nlinkRent}
            </HashLink>
            <HashLink
              to="/#client"
              smooth
              scroll={(el) => scrollWithOffset(el, -64)}
            >
              {locale[lang].nlinkRules}
            </HashLink>
            <NavLink to="/where-to">{locale[lang].nlinkWhere}</NavLink>
          </div>
          <div className="LinkBlock">
            <HashLink
              to="/#benefits"
              smooth
              scroll={(el) => scrollWithOffset(el, -64)}
            >
              {locale[lang].nlinkBenefits}
            </HashLink>
            <NavLink to="/tarifs">{locale[lang].nlinkTarifs}</NavLink>
          </div>
          <div className="LinkBlock">
            <NavLink to="/ur">{locale[lang].nlinkUr}</NavLink>

            <HashLink
              to="/#about"
              smooth
              scroll={(el) => scrollWithOffset(el, -64)}
            >
              {locale[lang].nlinkAbout}
            </HashLink>
          </div>
        </div>
        <div className="Contacts">
          <div className="Address">
            <Icon name="map-marker-alt" weight="solid" />
            <span>г. Мурманск, ул. Кооперативная 6, оф. 3</span>
          </div>
          <div className="BusyHours">
            <p>ПН - ВС: 09:00 - 21:00</p>
          </div>
          <a
            href="https://yandex.ru/maps/23/murmansk/?ll=33.105618%2C68.926442&mode=usermaps&source=constructorLink&um=constructor%3Ab00baa6941dcd2397889a9e7ab694cbd9403ad8938160690f97159cfaf7a8f77&z=17"
            target="blank"
          >
            Схема проезда
          </a>
        </div>
      </div>
      <div className="Copyrights DefaultContainer">
        © 2008-2023 Все права защищены.{' '}
        <a href="/docs/policy.pdf" target="blank">
          Политика конфиденциальности и обработки персональных данных
        </a>
      </div>
    </footer>
  )
}

export default Footer
