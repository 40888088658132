import React, { useContext } from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import './About.sass'
import Button from '../../ui/Button/Button'
import bgImage from './22.jpg'
import { AuthContext } from '../../App'
import Icon from '../../ui/Icon/Icon'
import locale from '../Top/LangChooser/locale/locale'

function About() {
  const { lang, contacts, setShowContactUs } = useContext(AuthContext)

  return (
    <section id="about" name="about" class="About">
      <div className="DefaultContainer">
        <p className="STitle">{locale[lang].aboutTitle}</p>
        <div class="About-Container">
          <div className="AboutImg">
            <img src={bgImage} alt="Автопрокат" />
          </div>
          <div class="About-Text">
            <p>{locale[lang].aboutText}</p>
            <div className="Benefits-Container">
              <p className="BenLine">
                <Icon name="check" weight="regular" />
                <span className="Title">{locale[lang].aboutB1}</span>
              </p>
              <p className="BenLine">
                <Icon name="check" weight="regular" />
                <span className="Title">{locale[lang].aboutB2}</span>
              </p>
              <p className="BenLine">
                <Icon name="check" weight="regular" />
                <span className="Title">{locale[lang].aboutB3}</span>
              </p>
            </div>

            <Button
              title="Арендовать авто"
              type="navhashlink"
              icon="cars"
              iconWeight="solid"
              path="#catalog"
              // onClick={() => (window.location.href = `tel:${contacts.phone}`)}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
