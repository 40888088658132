const ru = {
  nlinkRent: 'Арендовать автомобиль',
  nlinkBenefits: 'Наши преимущества',
  nlinkRules: 'Условия аренды',
  nlinkTarifs: 'Тарифы',
  nlinkUr: 'Юр. лицам',
  nlinkWhere: 'Куда поехать',
  nlinkAbout: 'О нас',
  nlinkContacts: 'Контакты',

  tooltipPass: 'Кол-во пассажиров',
  tooltipPriv: 'Полный привод',
  tooltipMount: 'Можно в Териберку',

  сarClassEkonom: 'Бюджет',
  сarClassComfort: 'Комфорт',
  сarClassBusiness: 'Бизнес',
  сarClass4wd: 'Кроссовер',

  catalogEmpty: 'Нет авто для показа. Попробуйте изменить фильтр',

  benefitsTitle: 'Наши преимущества',
  benefitsNewCars: 'Новые авто',
  benefitsNewCarsDesc:
    'В нашем автопарке представленны автомобили исключительно не старше 3х лет',
  benefitsInd: 'Индивидуальный подход к клиенту',
  benefitsIndDesc:
    'Условия аренды отвечают любым требованиям клиента. Информационная поддержка 24 ч. в сутки',
  benefitsFreedom: 'Свобода передвижения',
  benefitsFreedomDesc:
    'Возможность эксплуатации авто за пределами Мурманской обл. и территории РФ',
  benefitsExperience: 'Большой опыт',
  benefitsExperienceDesc:
    'Наша компания существует на рынке услуг более 10 лет. С нами работают многие ведущие российские и иностранные компании',

  clientTitle: 'Требования к клиентам',
  clientYears: 'Возраст арендатора от 23х лет',
  clientPassport: 'Наличие паспорта',
  clientDriverExperience:
    'Наличие водительского удостоверения, вод. стаж от 3х лет',

  aboutTitle: 'О компании',
  aboutText:
    'Мы — компания, представляющая свои услуги по прокату автомобилей в Мурманске более 15 лет. Наш парк автомобилей состоит из машин исключительно не старше трёх лет. Коллектив нашей компании заботится о том, чтоб сотрудничество с нами принесли вам только положительные эмоции.',
  aboutB1: 'Программа лояльности',
  aboutB2: 'Заключаем договора с юридическими лицами',
  aboutB3:
    'Возможность оформления КАСКО на арендуемый автомобиль по желанию арендатора',

  where2TeribTitle: 'Териберка',
  where2TeribText:
    'Териберка – древнее рыбацкое село на побережье Северного Ледовитого океана. Красоты Баренцева моря Северного Ледовитого океана и возможности для отдыха, рыбалки, морских прогулок, дайвинга и кайтсерфинга привлекают сюда из года в год все больше туристов не только с других регионов России, но и из-за рубежа (поселок вошел в 2016 году в топ-20 мировых туристических направлений по версии журнала National Geographic и известен по нашумевшей киноленте "Левиафан").',
  where2SeverTitle: 'Северное сияние',
  where2SeverText:
    'У вас появляется уникальная возможность посмотреть редчайшее природное явление, которое возникает только на северных широтах нашей планеты - северное сияние. Наблюдение за ним происходит с сентября по апрель в нашем регионе только лишь потому, что наступает полярный день. Наблюдать его вы можете не выходя из кабины вашей машины. Гид поможет вам разобраться с локациями и лучшими местами наблюдения за северным сиянием. Добро пожаловать к нам на полуостров поохотится за этим явлением!',
  where2HibTitle: 'Хибины',
  where2HibText:
    'Хибины - красивейшее место. Суровая сила севера, застывшая в каменных глыбах и легкость веселых ручейков. Невозмутимое молчание кристальных озер и бесконечный разговор многочисленных водопадов. Смешные березки, причудливые останцы, сокровища под ногами, красота полярного дня с закатом, длящимся часами...<br />Но Хибины - это не только чудесные пейзажи. Когда-то этот край был домом для целого народа. А значит, каждый уголок здесь имеет свою историю. Хибинская земля богата легендами и сказаниями… Знакомство с ними уже само по себе интересно и увлекательно.',
  where2TerTitle: 'Терский берег',
  where2TerText:
    'Варзуга - единственное село Терского берега, стоящее не у моря, в двадцати километрах от устья одноимённой реки. Расположенная севернее, она словно нависает над побережьем, и на протяжении веков была флагманом терских селений. Известная с 1466 года, именно Варзуга была столицей "волости Тре", и вплоть до основания Мурманска соперничала с Колой за роль центра Кольского полуострова. По пути в Варзугу вы можете заехать на Аметистовый берег, посмотреть древние лабиринты, познакомиться с Поморскими тонями ( быт и жизнь рыбаков), побывать на берегу Белого моря и увидеть часовенку неизвестного монаха…. А так же вы можете посетить уникальную деревню в песках – Кузомень, где пасутся дикие лошади.',
  where2SeidTitle: 'Сейдозеро',
  where2SeidText: `Легендарное Сейдозеро (Сейдъявр) – уникальное по красоте и количеству то ли артефактов, то ли просто природных останцев, место в Ловозерских тундрах. Сейдозеро (Мурманская область) находится в окрестностях пос. Ревда и пос. Ловозеро.<br /><br />
								Саамское название этого края — Луяврчорр, что значит горы у озера силы. Это популярное у туристов место, по высоте почти не уступающее соседним горам Хибинам и превосходящее их по глубине расщелин и ущелий.<br /><br />
								Впервые ученые пришли на берега Сейдозера в 1887 г. Оно было включено в маршрут Большой Кольской экспедиции, участниками которой были, в т.ч. В. Рамзай и А.Г. Петрелиус, хорошо известные туристам-любителям Хибин, ведь их имена носят перевалы этого горного массива.<br /><br />
								Озеро Сейдозеро активно изучается с 20-х годов XX века. Именно тогда сюда отправилась первая исследовательская экспедиция. С тех пор не утихают споры между учеными о происхождении расположенных здесь объектов. Эзотерики считают их останками древней Цивилизации – Гипербореи, поборники материалистической науки – творениями природы.`,

  tarifsTitle: 'Тарифы',
  tarifsModel: 'Модель авто',
  tarifsModelYear: 'Модельный ряд',
  tarifsKpp: 'Трансмиссия',
  tarifsSutki: 'Сутки',
  tarifsSutok: 'Суток',
  tarifsZalog: 'Залог',

  urTitle: 'Заказать коммерческое предложение',
  urText:
    'Наша компания с огромным удовольствием работает с корпоративными клиентами. У нас огромный опыт в сфере аренды автомобилей. Условия оказания наших услуг обсуждаются индивидуально с каждым клиентом.<br/>Закажите коммерческое предложение, и мы подберем для вас самые выгодные условия аренды.',
  urFormName: 'Наименование организации',
  urFormPhone: 'Контактный телефон',
  urFormTs: 'Количество требуемых тс',
  urFormRent: 'Предполагаемый срок аренды (дней)',
  urFormDop: 'Доп. информация и пожелания по бронированию',
  urFormOrder: 'Заказать предложение',
}

export default ru
