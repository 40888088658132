import { setHours, setMinutes } from 'date-fns'
import differenceInHours from 'date-fns/differenceInHours'
import { ceil, find, floor, split, toNumber } from 'lodash'
import getFormValues from '../../utils/newforms/getFormValues'

const recountForm = ({ data, nextForm }) => {
  recountFullDaysAndHours({ nextForm })
  recountDeliveryCost({ nextForm, settings: data.settings })
  recountOptionsPrice({ nextForm, optionsData: data.additionalOptions })
  recountCarTarif({ nextForm, carData: data.carData, settings: data.settings })

  return nextForm
}

const recountFullDaysAndHours = ({ nextForm }) => {
  const { delDate, delTime, retDate, retTime } = nextForm
  const [startingHour, startingMin] = split(delTime.value, ':')
  const [endingHour, endingMin] = split(retTime.value, ':')

  const dateStart = setMinutes(
    setHours(delDate.value, toNumber(startingHour)),
    toNumber(startingMin)
  )
  const dateEnd = setMinutes(
    setHours(retDate.value, toNumber(endingHour)),
    toNumber(endingMin)
  )

  const timeDiff = differenceInHours(dateEnd, dateStart)
  let fullDays = 0
  let fullHours = 0

  if (timeDiff <= 24) {
    fullDays = 1
    fullHours = 0
  } else {
    fullDays = floor(timeDiff / 24)
    fullHours = ceil(timeDiff - fullDays * 24)

    if (fullHours > 0 && fullHours <= 3) {
      fullDays += 0.5
    } else if (fullHours > 3) {
      fullDays += 1
    }
  }
  nextForm.counters.values.fullDays = !isNaN(fullDays) ? fullDays : 0
  nextForm.counters.values.fullHours = !isNaN(fullHours) ? fullHours : 0
}

const recountDeliveryCost = ({ nextForm, settings }) => {
  const { delTime, retTime, delType, retType } = nextForm

  const startingHour = toNumber(split(delTime.value, ':')[0])
  const startingMin = toNumber(split(delTime.value, ':')[1])
  const endingHour = toNumber(split(retTime.value, ':')[0])
  const endingMin = toNumber(split(retTime.value, ':')[1])

  let deliveryPrice = 0
  let returnPrice = 0

  if (
    (startingHour > 9 && startingHour < 20) ||
    (startingHour === 9 && startingMin >= 30) ||
    (startingHour === 20 && startingMin <= 30)
  ) {
    deliveryPrice = getCostByTypeInBusyHours(delType.values, settings)
  } else {
    deliveryPrice = getCostByTypeInSleepHours(retType.values, settings)
  }

  if (
    (endingHour > 9 && endingHour < 20) ||
    (endingHour === 9 && endingMin >= 30) ||
    (endingHour === 20 && endingMin <= 30)
  ) {
    returnPrice = getCostByTypeInBusyHours(retType.values, settings)
  } else {
    returnPrice = getCostByTypeInSleepHours(retType.values, settings)
  }

  nextForm.counters.values.delivery = deliveryPrice
  nextForm.counters.values.return = returnPrice
}

const getCostByTypeInBusyHours = (type, settings) => {
  switch (type) {
    case 'office':
      return +settings.officeBusy
    case 'airport':
      return +settings.airBusy
    case 'other':
      return +settings.otherBusy
    default:
      return 0
  }
}

const getCostByTypeInSleepHours = (type, settings) => {
  switch (type) {
    case 'office':
      return +settings.officeOther
    case 'airport':
      return +settings.airOther
    case 'other':
      return +settings.otherOther
    default:
      return 0
  }
}

const recountCarTarif = ({ nextForm, carData, settings }) => {
  const { transmission, counters } = nextForm
  const carTarif = find(carData.price, ['transmission', transmission.values])
  const zalog = carTarif.zalog
  const { fullDays } = counters.values

  let rentPrice = 0

  if (fullDays <= 3) {
    rentPrice = carTarif.to3
  } else if (fullDays <= 14) {
    rentPrice = carTarif.to14
  } else if (fullDays <= 30) {
    rentPrice = carTarif.to30
  } else {
    rentPrice = carTarif.from30
  }

  nextForm.counters.values = {
    ...nextForm.counters.values,
    rentPrice,
    zalog,
  }
}

const recountOptionsPrice = ({ nextForm, optionsData }) => {
  const values = getFormValues({ form: nextForm })
  const { additionalOptions, counters } = values

  let optionsTotalCost = 0
  additionalOptions.forEach((o) => {
    const optionData = find(optionsData, ['optionId', o])
    if (optionData) {
      if (optionData.priceType === 'once') {
        optionsTotalCost += toNumber(optionData.price)
      } else {
        optionsTotalCost += counters.fullDays * toNumber(optionData.price)
      }
    }
  })
  nextForm.counters.values.options = optionsTotalCost
}

export default recountForm
