import React, { useRef } from 'react'
import './Booking.sass'
import CarForm from './components/CarForm/CarForm'
import { useState } from 'react'
import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { getUrlQueryParams } from '../../utils/queries/getUrlQueryParams'
import promiseAllValues from '../../utils/promiseAllValues'
import getDoc from '../../utils/db/getDoc'
import { cloneDeep, isEmpty } from 'lodash'
import AdditionalOptions from './components/AdditionalOptions/AdditionalOptions'
import getCollection from '../../utils/db/getCollection'
import PlacePicker from './components/PlacePicker/PlacePicker'
import getFieldRenderObject from '../../utils/newforms/render/getFieldRenderObject'
import { createForm } from '../../utils/newforms/createForm'
import UserForm from './components/UserForm/UserForm'
import PaymentMethods from './components/PaymentMethods/PaymentMethods'
import TotalsBlock from './components/TotalsBlock/TotalsBlock'
import Spinner from '../../ui/Spinner/Spinner'
import getFormValues from '../../utils/newforms/getFormValues'
import recountForm from './recountForm'
import isFormValid from '../../utils/newforms/validation/isFormValid'
import StatusMessage, {
  useStatusMessage,
} from '../../ui/StatusMessage/StatusMessage'
import scrollWithOffset from '../../ui/ScrollToTop/scrollWithOffset'
import o1 from '../../components/Client/assets/objects/ClientsCircle.png'
import addDoc from '../../utils/db/addDoc'
import sendOrderEmail from './sendOrderEmail'

function Booking({ ...router }) {
  const searchString = router.location.search
  const { carId, transmission } = getUrlQueryParams({
    searchString,
    params: ['carId', 'transmission'],
  })
  const [form, setForm] = useState(
    createForm({ formPattern: new BookingForm({ carId }) })
  )
  const [showErrors, setShowErrors] = useState(false)
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()
  const delRef = useRef()
  const returnRef = useRef()
  const formRef = useRef()
  const paymentRef = useRef()

  console.log('🚀 ~ file: Booking.jsx:23 ~ Booking ~ form:', form)

  useEffect(() => {
    promiseAllValues({
      carData: getDoc({ path: 'cars', docId: carId, docIdName: 'carId' }),
      additionalOptions: getCollection({
        path: 'options',
        docIdName: 'optionId',
      }),
      settings: getDoc({ path: 'settings', docId: 'BqOykJugZ5mnBAAPocze' }),
    }).then((result) => {
      setData(result)
      const clone = cloneDeep(form)
      if (transmission === 'A' || transmission === 'M') {
        clone.transmission.values = transmission
      } else {
        clone.transmission.values = result.carData.price[0].transmission
      }
      clone.counters.values.carwash = result.settings.carwash
      setForm(clone)
    })
  }, [])

  const onAdditionalOptionClicked = (id) => {
    const clone = cloneDeep(form)
    clone.additionalOptions.values = clone.additionalOptions.values.includes(id)
      ? clone.additionalOptions.values.filter((i) => i !== id)
      : [...clone.additionalOptions.values, id]
    updateForm(clone)
  }

  const onPlaceClicked = (id, field) => {
    const clone = cloneDeep(form)
    clone[field].values = id
    updateForm(clone)
  }

  const onPaymentClicked = (id) => {
    const clone = cloneDeep(form)
    clone.paymentType.values = id
    updateForm(clone)
  }

  const updateForm = (nextForm) => {
    setForm(recountForm({ data, nextForm }))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    if (
      !isFormValid({ form, checkFields: ['delDate', 'delTime'] }) ||
      isEmpty(form.delType.values)
    ) {
      setStatusMessage({
        show: true,
        section: 'delivery',
        type: 'fail',
        message:
          'Пожалуйста, заполните все поля формы и выберите метод подачи авто',
        closeAfter: 5000,
      })
      scrollWithOffset(delRef.current, -128)

      return setShowErrors(true)
    }

    if (
      !isFormValid({ form, checkFields: ['retDate', 'reTime'] }) ||
      isEmpty(form.retType.values)
    ) {
      setStatusMessage({
        show: true,
        section: 'return',
        type: 'fail',
        message:
          'Пожалуйста, заполните все поля формы и выберите метод возврата авто',
        closeAfter: 5000,
      })
      scrollWithOffset(returnRef.current, -128)

      return setShowErrors(true)
    }

    if (!isFormValid({ form })) {
      setStatusMessage({
        show: true,
        section: 'form',
        type: 'fail',
        message:
          'Пожалуйста, заполните все поля формы и выберите метод возврата авто',
        closeAfter: 5000,
      })
      scrollWithOffset(formRef.current, -128)

      return setShowErrors(true)
    }

    if (isEmpty(form.paymentType.values)) {
      setStatusMessage({
        show: true,
        section: 'payment',
        type: 'fail',
        message: 'Пожалуйста, выберите способ оплаты',
        closeAfter: 5000,
      })
      scrollWithOffset(paymentRef.current, -128)

      return setShowErrors(true)
    }

    setShowErrors(false)
    setIsLoading(true)

    const values = getFormValues({ form })
    Promise.all([
      sendOrderEmail(values),
      addDoc({ path: 'newOrders', data: values }),
    ]).then(() => router.history.push(`/success?carId=${carId}`))
  }

  return (
    <di className="Booking" style={{ backgroundImage: `url(${o1})` }}>
      <div className="DefaultContainer">
        <div className="Booking-Container">
          {!isEmpty(data) && !isEmpty(form.transmission.values) ? (
            <>
              <div className="BookingForm">
                <CarForm
                  rentPrice={form.counters.values.rentPrice}
                  carData={data.carData}
                  transmission={form.transmission.values}
                  onTransmissionPicked={(id) => {
                    const clone = cloneDeep(form)
                    clone.transmission.values = id
                    updateForm(clone)
                  }}
                />
                <AdditionalOptions
                  additionalOptions={data.additionalOptions}
                  pickedOptions={form.additionalOptions.values}
                  onAdditionalOptionClicked={onAdditionalOptionClicked}
                />
                <PlacePicker
                  ref={delRef}
                  section="delivery"
                  statusMessage={statusMessage}
                  step={2}
                  title={'Куда подать?'}
                  settings={data.settings}
                  form={form}
                  setForm={updateForm}
                  showErrors={showErrors}
                  fields={['delDate', 'delTime']}
                  place={form.delType.values}
                  onPlaceClicked={(id) => onPlaceClicked(id, 'delType')}
                />
                <PlacePicker
                  ref={returnRef}
                  section="return"
                  statusMessage={statusMessage}
                  step={3}
                  title={'Где забрать?'}
                  settings={data.settings}
                  form={form}
                  setForm={updateForm}
                  showErrors={showErrors}
                  fields={['retDate', 'retTime']}
                  place={form.retType.values}
                  onPlaceClicked={(id) => onPlaceClicked(id, 'retType')}
                />
                <UserForm
                  ref={formRef}
                  statusMessage={statusMessage}
                  form={form}
                  setForm={updateForm}
                  showErrors={showErrors}
                />
                <PaymentMethods
                  ref={paymentRef}
                  statusMessage={statusMessage}
                  paymentType={form.paymentType.values}
                  onPaymentClicked={onPaymentClicked}
                />
              </div>
              <TotalsBlock
                counters={form.counters.values}
                paymentType={form.paymentType.values}
                onSubmit={onSubmit}
                isLoading={isLoading}
              />
            </>
          ) : (
            <Spinner type="module" />
          )}
        </div>
      </div>
    </di>
  )
}

class BookingForm {
  constructor({ carId }) {
    this.userFio = {
      field: {
        fieldId: 'userFio',
        fieldType: 'input',
        inputType: 'text',
        label: 'Ваше фио',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.userBdate = {
      field: {
        fieldId: 'userBdate',
        fieldType: 'input',
        inputType: 'text',
        label: 'Дата рождения',
        mask: 'date',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.userEmail = {
      field: {
        fieldId: 'userEmail',
        fieldType: 'input',
        inputType: 'email',
        validationPattern: 'email',
        label: 'E-mail',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.userPhone = {
      field: {
        fieldId: 'userPhone',
        fieldType: 'phone',
        inputType: 'text',
        label: 'Контактный телефон',
        required: true,
      },
      render: getFieldRenderObject(),
    }

    this.userFact = {
      field: {
        fieldId: 'userFact',
        fieldType: 'textarea',
        label: 'Адрес фактического проживания',
      },
      render: getFieldRenderObject(),
    }
    this.delLocation = {
      field: {
        fieldId: 'delLocation',
        fieldType: 'input',
        inputType: 'text',
        label: 'Укажите Ваш адрес',
      },
      render: getFieldRenderObject(),
    }
    this.retLocation = {
      field: {
        fieldId: 'retLocation',
        fieldType: 'input',
        inputType: 'text',
        label: 'Укажите Ваш адрес',
      },
      render: getFieldRenderObject(),
    }

    this.userComment = {
      field: {
        fieldId: 'userComment',
        fieldType: 'textarea',
        label: 'Пожелания по бронированию',
      },
      render: getFieldRenderObject(),
    }
    this.delDate = {
      field: {
        fieldId: 'delDate',
        fieldType: 'date',
        inputType: 'text',
        label: 'Дата',
        required: true,
        iconPosition: 'left',
      },
      render: getFieldRenderObject(),
    }
    this.delTime = {
      field: {
        fieldId: 'delTime',
        fieldType: 'input',
        inputType: 'text',
        label: 'Время',
        required: true,
        icon: 'clock',
        iconPosition: 'left',
        mask: 'time',
      },
      render: getFieldRenderObject(),
    }
    this.retDate = {
      field: {
        fieldId: 'retDate',
        fieldType: 'date',
        inputType: 'text',
        label: 'Дата',
        required: true,
        iconPosition: 'left',
      },
      render: getFieldRenderObject(),
    }
    this.retTime = {
      field: {
        fieldId: 'retTime',
        fieldType: 'input',
        inputType: 'text',
        label: 'Время',
        required: true,
        icon: 'clock',
        iconPosition: 'left',
        mask: 'time',
      },
      render: getFieldRenderObject(),
    }
    this.passportSeria = {
      field: {
        fieldId: 'passportSeria',
        fieldType: 'input',
        inputType: 'text',
        label: 'Серия, номер',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.passportKem = {
      field: {
        fieldId: 'passportKem',
        fieldType: 'input',
        inputType: 'text',
        label: 'Кем выдан',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.passportDate = {
      field: {
        fieldId: 'passportDate',
        fieldType: 'input',
        inputType: 'text',
        label: 'Дата выдачи',
        mask: 'date',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.passportAddress = {
      field: {
        fieldId: 'passportAddress',
        fieldType: 'input',
        inputType: 'text',
        label: 'Адрес регистрации по паспорту',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.vodSeria = {
      field: {
        fieldId: 'vodSeria',
        fieldType: 'input',
        inputType: 'text',
        label: 'Серия, номер',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.vodStage = {
      field: {
        fieldId: 'vodStage',
        fieldType: 'input',
        inputType: 'text',
        label: 'Стаж вождения (кол-во лет)',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.transmission = {
      field: { fieldId: 'transmission', value: null },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.additionalOptions = {
      field: { fieldId: 'additionalOptions', value: [] },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.delType = {
      field: { fieldId: 'delType', value: null },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.retType = {
      field: { fieldId: 'retType', value: null },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.paymentType = {
      field: { fieldId: 'paymentType', value: null },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.counters = {
      field: {
        fieldId: 'counters',
        value: {
          fullDays: 0,
          fullHours: 0,
          delivery: 0,
          return: 0,
          options: 0,
          rentPrice: 0,
          zalog: 0,
          carwash: 0,
        },
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.carId = {
      field: { fieldId: 'carId', value: carId },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.created = {
      field: { fieldId: 'created', value: new Date() },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.updated = {
      field: { fieldId: 'created', value: new Date() },
      render: getFieldRenderObject({ isSystem: true }),
    }
  }
}

export default withRouter(Booking)
