import React, { useEffect, createContext, useState } from 'react'
import { Route } from 'react-router-dom'
import Home from './pages/Home/Home'
import SitePage from './pages/SitePage/SitePage'
import { firebase } from './config/firebase'
import ScrollToTop from './ui/ScrollToTop/ScrollToTop'
import CookieNotification from './components/CookieNotification/CookieNotification'
// import TourPage from './components/TourPage/TourPage'
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'
import getDoc from './utils/db/getDoc'
import Services from './pages/Services/Services'
import TextPage from './pages/TextPage/TextPage'
import Scientific from './pages/Scientific/Scientific'
import { isEmpty } from 'lodash'
import Contacts from './pages/Contacts/Contacts'
import Doctors from './components/Doctors/Doctors'
import DoctorsPage from './pages/DoctorsPage/DoctorsPage'
import WhereTo from './pages/WhereTo/WhereTo'
import Tarifs from './pages/Tarifs/Tarifs'
import Ur from './pages/Ur/Ur'
import Booking from './pages/Booking/Booking'
import Success from './pages/Success/Success'

export const AuthContext = createContext(null)

const AuthProvider = ({ children }) => {
  // const [user, setUser] = useState(null)
  const [showContactUs, setShowContactUs] = useState({
    show: false,
    title: '',
    emailSubject: '',
  })
  const [lang, setLang] = useState(localStorage.getItem('lang') || 'ru')
  const [contacts, setContacts] = useState(null)

  useEffect(() => {
    getDoc({ path: 'pages', docId: 'contacts' }).then((data) =>
      setContacts(data)
    )
  }, [])

  // useEffect(() => {
  //   const userStateListener = firebase.auth().onAuthStateChanged(setUser)
  //   return () => {
  //     userStateListener()
  //   }
  // }, [])

  return (
    <AuthContext.Provider
      value={{ contacts, showContactUs, setShowContactUs, lang, setLang }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function App() {
  return (
    <AuthProvider>
      <ScrollToTop />
      <SitePage>
        <Route path="/" exact component={Home} />
        <Route path="/where-to" exact component={WhereTo} />
        <Route path="/tarifs" exact component={Tarifs} />
        <Route path="/ur" exact component={Ur} />
        <Route path="/book" exact component={Booking} />
        <Route path="/success" exact component={Success} />

        {/* <Route path="/project/:id" exact component={ProjectPage} />
        <Route
          path={['/manage-project', '/manage-project/:id']}
          exact
          component={ManageProjectPage}
        />
        <Route path="/admin" exact component={Auth} /> */}
      </SitePage>
      <CookieNotification />
    </AuthProvider>
  )
}

export default App
