import React, { useContext } from 'react'
import locale from '../../Top/LangChooser/locale/locale'
import { AuthContext } from '../../../App'
import './ClassFilter.sass'

function ClassFilter({ activeClass, setClass }) {
  const { lang } = useContext(AuthContext)

  const data = [
    { id: 'all', title: 'Все' },
    { id: '1', title: locale[lang].сarClassEkonom },
    { id: '2', title: locale[lang].сarClassComfort },
    { id: '3', title: locale[lang].сarClassBusiness },
    { id: '4', title: locale[lang].сarClass4wd },
  ]

  return (
    <ul className="ClassFilter">
      {data.map((c) => (
        <li key={`CarClassFilter-${c.id}`} onClick={() => setClass(c.id)}>
          <span className={`Title ${activeClass === c.id && 'ActiveClass'}`}>
            {c.title}
          </span>
        </li>
      ))}
    </ul>
  )
}

export default ClassFilter
