import React, { useContext } from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import locale from '../Top/LangChooser/locale/locale'
import { AuthContext } from '../../App'
import i1 from './assets/icons/newcars.svg'
import i2 from './assets/icons/clients.svg'
import i3 from './assets/icons/way.svg'
import i4 from './assets/icons/exp.svg'
import './Benefits.sass'
import o1 from './assets/objects/benefitsLeft.png'
import o2 from './assets/objects/benefitsRight.png'

function Benefits() {
  const { lang } = useContext(AuthContext)
  const data = [
    {
      icon: i1,
      title: locale[lang].benefitsNewCars,
      description: locale[lang].benefitsNewCarsDesc,
    },
    {
      icon: i2,
      title: locale[lang].benefitsInd,
      description: locale[lang].benefitsIndDesc,
    },
    {
      icon: i3,
      title: locale[lang].benefitsFreedom,
      description: locale[lang].benefitsFreedomDesc,
    },
    {
      icon: i4,
      title: locale[lang].benefitsExperience,
      description: locale[lang].benefitsExperienceDesc,
    },
  ]
  return (
    <section
      id="benefits"
      name="benefits"
      class="Benefits"
      style={{ backgroundImage: `url(${o1}), url(${o2})` }}
    >
      <div className="DefaultContainer">
        <p className="STitle">{locale[lang].benefitsTitle}</p>
        <div className="Benefits-Container">
          {data.map((b) => (
            <BenefitTile key={`bIcon-${b.icon}`} {...b} />
          ))}
        </div>
      </div>
    </section>
  )
}

function BenefitTile({ icon, title, description }) {
  return (
    <div class="BenefitTile">
      <img src={icon} alt={title} />
      <p className="Title">{title}</p>
      <p className="Description">{description}</p>
    </div>
  )
}

export default Benefits

{
  /* <div class="Benefit">
                    <img src="/img/icons/newcars.svg" alt="">
                    <h5><?php echo $locale['Benefits-NewCars']; ?></h5>
                    <p><?php echo $locale['Benefits-NewCarsDesc']; ?></p>
                </div>

                <div class="Benefit">
                    <img src="/img/icons/clients.svg" alt="">
                    <h5><?php echo $locale['Benefits-Ind']; ?></h5>
                    <p><?php echo $locale['Benefits-IndDesc']; ?></p>
                </div>

                <div class="Benefit">
                    <img src="/img/icons/way.svg" alt="">
                    <h5><?php echo $locale['Benefits-Freedom']; ?></h5>
                    <p><?php echo $locale['Benefits-FreedomDesc']; ?></p>
                </div>

                <div class="Benefit">
                    <img src="/img/icons/exp.svg" alt="">
                    <h5><?php echo $locale['Benefits-Experience']; ?></h5>
                    <p><?php echo $locale['Benefits-ExperienceDesc']; ?></p>
                </div> */
}
