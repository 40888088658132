import React, { forwardRef } from 'react'
import BookingTitle from '../BookingTitle/BookingTitle'
import PickOption from '../../../../ui/PickOption/PickOption'
import './PlacePicker.sass'
import FormRender from '../../../../utils/newforms/render/FormRender'
import StatusMessage from '../../../../ui/StatusMessage/StatusMessage'

const PlacePicker = forwardRef(function (
  {
    statusMessage,
    section,
    step,
    title,
    settings,
    form,
    setForm,
    fields,
    showErrors,
    place,
    onPlaceClicked,
  },
  ref
) {
  return (
    <div ref={ref} className="PlacePicker">
      <BookingTitle step={step} title={title} />

      <div className="PlacePicker-Container">
        {statusMessage.show && statusMessage.section === section && (
          <StatusMessage
            className="Site-StatusMessage"
            type={statusMessage.type}
            message={statusMessage.message}
          />
        )}
        <div className="Fields">
          <FormRender
            form={form}
            setForm={setForm}
            sections={[{ fields }]}
            errors={showErrors}
          />
        </div>
        <PickOption
          isActive={place === 'office'}
          title={'🏢 В офис'}
          description={'г. Мурманск, ул. Кооперативная 6'}
          useAdditionalInfo
          busyPrice={settings.officeBusy}
          otherPrice={settings.officeOther}
          onClick={() => onPlaceClicked('office')}
        />
        <PickOption
          isActive={place === 'airport'}
          title={'🛫 В Аэропорт'}
          description={
            'Мурманская область, п.Мурмаши, аэропорт Мурманск имени Николая II'
          }
          useAdditionalInfo
          busyPrice={settings.airBusy}
          otherPrice={settings.airOther}
          onClick={() => onPlaceClicked('airport')}
        />
        <PickOption
          isActive={place === 'other'}
          title={'🗺️ Другой адрес в г. Мурманск'}
          description={
            <FormRender
              form={form}
              setForm={setForm}
              sections={[
                {
                  fields: [
                    section === 'delivery' ? 'delLocation' : 'retLocation',
                  ],
                },
              ]}
              errors={showErrors}
            />
          }
          useAdditionalInfo
          busyPrice={settings.otherBusy}
          otherPrice={settings.otherOther}
          onClick={() => onPlaceClicked('other')}
        />
      </div>
    </div>
  )
})

export default PlacePicker
