import React from 'react'
import './Home.sass'
import Hero from '../../components/Hero/Hero'
import Catalog from '../../components/Catalog/Catalog'
import Benefits from '../../components/Benefits/Benefits'
import Client from '../../components/Client/Client'
import About from '../../components/About/About'

function Home() {
  return (
    <div className="Home">
      <Hero />
      <Catalog />
      <Benefits />
      <Client />
      <About />
      {/* <Calc />
      <Style />
      <PortfolioCarousel />
      <RequestConsultation />
      <FeedsCarousel />
      <WhatULearn /> */}
      {/* <Partners /> */}
    </div>
  )
}

export default Home
