import { isArray, isEmpty, isString } from 'lodash'
import { parsePhoneNumberFromString } from 'libphonenumber-js/max'
import * as VALIDATION_PATTERNS from './validationPatterns'
import * as FIELD_ERRORS from '../fieldErrors'

export const checkValidity = ({ fieldType, value, validation, ...rest }) => {
  let isValid = {
    status: true,
    error: '',
  }

  if (fieldType === 'phone') {
    const number = parsePhoneNumberFromString(value.value, rest.country)
    if (number && number.isValid()) {
      return {
        status: true,
        error: '',
      }
    }

    return {
      status: false,
      error: FIELD_ERRORS.PHONE_NOT_VALID,
    }
  }

  if (fieldType === 'address') {
    if (value.formattedAddress) {
      return {
        status: true,
        error: '',
      }
    }

    return {
      status: false,
      error: '',
    }
  }

  if (fieldType === 'date') {
    if (value) {
      return {
        status: true,
        error: '',
      }
    }

    return {
      status: false,
      error: '',
    }
  }

  if (!validation) {
    return true
  }

  if (
    validation.required &&
    fieldType !== 'checkbox' &&
    fieldType !== 'inputFile'
  ) {
    switch (validation.pattern) {
      case 'email':
        if (VALIDATION_PATTERNS.EMAIL.pattern.test(value) && isValid.status) {
          isValid = {
            status: true,
            error: '',
          }
        } else {
          isValid = {
            status: false,
            error: VALIDATION_PATTERNS.EMAIL.error,
          }
        }
        break

      case 'password':
        if (value.length >= 6 && isValid.status) {
          isValid = {
            status: true,
            error: '',
          }
        } else {
          isValid = {
            status: false,
            error: VALIDATION_PATTERNS.PASSWORD.error,
          }
        }
        break
      case 'phone':
        if (VALIDATION_PATTERNS.PHONE.pattern.test(value) && isValid.status) {
          isValid = {
            status: true,
            error: '',
          }
        } else {
          isValid = {
            status: false,
            error: VALIDATION_PATTERNS.PHONE.error,
          }
        }
        break

      default:
        if (
          (isString(value) && value.trim() !== '' && isValid.status) ||
          (isArray(value) && !isEmpty(value) && isValid.status)
        ) {
          isValid = {
            status: true,
            error: '',
          }
        } else {
          isValid = {
            status: false,
            error: FIELD_ERRORS.EMPTY_FIELD,
          }
        }
        break
    }
  }

  if (validation.required && fieldType === 'inputFile') {
    if (value) {
      isValid = {
        status: true,
        error: '',
      }
    } else {
      isValid = {
        status: false,
        error: FIELD_ERRORS.EMPTY_FIELD,
      }
    }
  }

  return isValid
}
