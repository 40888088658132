import React, { useContext, useState } from 'react'
import './Tarifs.sass'
import { AuthContext } from '../../App'
import locale from '../../components/Top/LangChooser/locale/locale'
import Spinner from '../../ui/Spinner/Spinner'
import { isEmpty, orderBy } from 'lodash'
import { tr } from 'date-fns/locale'
import { useEffect } from 'react'
import getCollection from '../../utils/db/getCollection'
import Button from '../../ui/Button/Button'
import Media from 'react-media'

function Tarifs() {
  const [price, setPrice] = useState(null)
  const { lang } = useContext(AuthContext)

  useEffect(() => {
    getCollection({
      path: 'cars',
      docIdName: 'carId',
      whereQueries: [{ fieldPath: 'isPublished', op: '==', value: true }],
    }).then((data) => {
      const arr = []
      data.forEach((d) => {
        arr.push({
          ...d,
          price: d.price[0],
        })
        if (d.price.length > 1) {
          arr.push({
            ...d,
            price: d.price[1],
          })
        }
      })
      setPrice(orderBy(arr, ['title'], ['asc']))
    })
  }, [])

  return (
    <div className="Tarifs">
      <div className="DefaultContainer">
        <div className="Tarifs-Container">
          <div className="STitle">{locale[lang].tarifsTitle}</div>
          <Media
            query="(max-width: 768px)"
            render={() => (
              <TarifsMobileTiles price={price} locale={locale} lang={lang} />
            )}
          />
          <table className="DesktopTable">
            <thead>
              <tr>
                <th>{locale[lang].tarifsModel}</th>
                <th>{locale[lang].tarifsModelYear}</th>
                <th style={{ textAlign: 'center' }}>
                  {locale[lang].tarifsKpp}
                </th>
                <th>1-3 {locale[lang].tarifsSutki}</th>
                <th>4-14 {locale[lang].tarifsSutok}</th>
                <th>15-30 {locale[lang].tarifsSutok}</th>
                <th>{`> 30 ${locale[lang].tarifsSutok}`}</th>
                <th>{locale[lang].tarifsZalog}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!isEmpty(price) ? (
                ''
              ) : (
                <tr>
                  <td colSpan={9}>
                    <Spinner type="module" />
                  </td>
                </tr>
              )}
              {!isEmpty(price) &&
                price.map((p, i) => (
                  <tr className={`${i % 2 ? 'Rib' : ''}`}>
                    <td>{p.title}</td>
                    <td>{p.modelYear}</td>
                    <td style={{ textAlign: 'center' }}>
                      {p.price.transmission}
                    </td>
                    <td>{p.price.to3} ₽</td>
                    <td>{p.price.to14} ₽</td>
                    <td>{p.price.to30} ₽</td>
                    <td>{p.price.from30} ₽</td>
                    <td>{p.price.zalog} ₽</td>
                    <td>
                      <Button
                        title="Выбрать авто"
                        type="navlink"
                        path={`/book?carId=${p.carId}&transmission=${p.price.transmission}`}
                        size={24}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

function TarifsMobileTiles({ price, locale, lang }) {
  return (
    <div className="TarifsMobileTiles">
      {!isEmpty(price) &&
        price.map((p, i) => (
          <div className="TarifTile">
            <p className="Title">
              {p.title}, {p.modelYear}
            </p>
            <p className="DataLine">
              {locale[lang].tarifsKpp}: <span>{p.price.transmission}</span>
            </p>
            <table>
              <thead>
                <tr>
                  <th>1-3 {locale[lang].tarifsSutki}</th>
                  <th>4-14 {locale[lang].tarifsSutok}</th>
                  <th>15-30 {locale[lang].tarifsSutok}</th>
                  <th>{`> 30 ${locale[lang].tarifsSutok}`}</th>
                  <th>{locale[lang].tarifsZalog}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{p.price.to3} ₽</td>
                  <td>{p.price.to14} ₽</td>
                  <td>{p.price.to30} ₽</td>
                  <td>{p.price.from30} ₽</td>
                  <td>{p.price.zalog} ₽</td>
                </tr>
              </tbody>
            </table>
            <Button
              title="Выбрать авто"
              type="navlink"
              path={`/book?carId=${p.carId}&transmission=${p.price.transmission}`}
              size={48}
            />
          </div>
        ))}
    </div>
  )
}

{
  /* <tr className={`${i % 2 ? 'Rib' : ''}`}>
            <td>{p.title}</td>
            <td>{p.modelYear}</td>
            <td style={{ textAlign: 'center' }}>{p.price.transmission}</td>
            <td>{p.price.to3} ₽</td>
            <td>{p.price.to14} ₽</td>
            <td>{p.price.to30} ₽</td>
            <td>{p.price.from30} ₽</td>
            <td>{p.price.zalog} ₽</td>
            <td>
              <Button
                title="Выбрать авто"
                type="navlink"
                path={`/book?carId=${p.carId}&transmission=${p.price.transmission}`}
                size={24}
              />
            </td>
          </tr> */
}

export default Tarifs
