import React, { useContext } from 'react'
import BookingTitle from '../BookingTitle/BookingTitle'
import { find, min } from 'lodash'
import { Tooltip } from '@mui/material'
import locale from '../../../../components/Top/LangChooser/locale/locale'
import { AuthContext } from '../../../../App'
import Icon from '../../../../ui/Icon/Icon'
import './CarForm.sass'
import PickOption from '../../../../ui/PickOption/PickOption'

function CarForm({ carData, rentPrice, transmission, onTransmissionPicked }) {
  const avatar = find(carData.photos, ['isAvatar', true])
  const { lang } = useContext(AuthContext)

  return (
    <div className="CarForm">
      <BookingTitle step="1" title="Ваш автомобиль" />
      <div className="Car">
        <div className="Data">
          <h4 className="Title">
            {carData.title}{' '}
            <span className="ModelYear">{carData.modelYear}</span>
          </h4>
          <div className="PriceLine">
            <p>
              {rentPrice ? (
                <span className="Price">{rentPrice} ₽</span>
              ) : (
                <>
                  от{' '}
                  <span className="Price">
                    {min(carData.price.map((p) => p.from30))} ₽
                  </span>
                </>
              )}
              ./сутки
            </p>
            <div className="PriceTable">
              <Icon name="question-circle" weight="solid" />
              <div className="PriceBox">
                <table>
                  <tr>
                    <th></th>
                    <th>1-3 сутки</th>
                    <th>4-14 суток</th>
                    <th>15-30 суток</th>
                    <th>> 30 суток</th>
                    <th>Залог</th>
                  </tr>
                  {carData.price.map((p) => (
                    <tr>
                      <td>{p.transmission}КПП</td>
                      <td>{p.to3} ₽</td>
                      <td>{p.to14} ₽</td>
                      <td>{p.to30} ₽</td>
                      <td>{p.from30} ₽</td>
                      <td>{p.zalog} ₽</td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
          </div>
          <p className="Transmission">
            {carData.price.map((p) => (
              <PickOption
                isActive={p.transmission === transmission}
                key={`${carData.carId}-${p.transmission}`}
                title={p.transmission === 'A' ? 'Автомат' : 'Механика'}
                onClick={() => onTransmissionPicked(p.transmission)}
              />
            ))}
          </p>
        </div>
        <div className="Tile">
          <div className="CarTags">
            <Tooltip title={locale[lang].tooltipPass} placement="right-start">
              <p className="Tag">
                <Icon name="user" weight="solid" />
                {carData.passangers}
              </p>
            </Tooltip>
            {carData.wd === '1' && (
              <Tooltip title={locale[lang].tooltipPriv} placement="right-start">
                <p className="Tag">
                  <span>4WD</span>
                </p>
              </Tooltip>
            )}
            {carData.mountains && (
              <Tooltip
                title={locale[lang].tooltipMount}
                placement="right-start"
              >
                <p className="Tag">
                  <Icon name="mountains" weight="solid" />
                </p>
              </Tooltip>
            )}
          </div>
          <img className="CarAvatar" src={avatar.publicUrl} alt="" />
        </div>
      </div>
    </div>
  )
}

export default CarForm
