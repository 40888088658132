import React, { useState, useContext, useEffect } from 'react'
import './ContactUsForm.sass'
import Button from '../../../ui/Button/Button'
import { createForm } from '../../../utils/newforms/createForm'
import getFieldRenderObject from '../../../utils/newforms/render/getFieldRenderObject'
import FormRender from '../../../utils/newforms/render/FormRender'
import isFormValid from '../../../utils/newforms/validation/isFormValid'
import getFormValues from '../../../utils/newforms/getFormValues'
import StatusMessage, {
  useStatusMessage,
} from '../../../ui/StatusMessage/StatusMessage'
import { functions } from '../../../config/firebase'
import { AuthContext } from '../../../App'
import location from '../../../locale/location'

function ContactUsForm({
  subject = "Зворотній зв'язок",
  additionalContent = null,
}) {
  const [form, setForm] = useState(
    createForm({ formPattern: new ContactForm() })
  )
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()

  const onSubmit = (e) => {
    e.preventDefault()
    if (!isFormValid({ form })) {
      setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Будь ласка, заповніть усі поля форми',
        closeAfter: 5000,
      })
      return setShowErrors(true)
    }
    setShowErrors(false)
    setIsLoading(true)

    const values = getFormValues({ form })

    const func = functions.httpsCallable('emailSender')
    func({
      subject,
      to: 'iseeblog1@gmail.com',
      html: `
      Имя: ${values.name}<br/>
      E-mail: ${values.email}<br/>
      Номер телефона: ${values.phone.value}<br/>
      Комментарий: ${values.comment}<br/>
      ${additionalContent || ''}`,
    }).then(({ data }) => {
      if (data === 'success') {
        setStatusMessage({
          show: true,
          type: 'success',
          message: "Ми отримали ваш запит, очікуйте зв'язку з оператором",
          closeAfter: 5000,
        })
        setForm(createForm({ formPattern: new ContactForm() }))
        return setIsLoading(false)
      }
    })
  }

  return (
    <div className="ContactUs-Form">
      <h5>Зворотній звязок</h5>
      <form onSubmit={onSubmit}>
        <div className="Form-Fields">
          {statusMessage.show && (
            <StatusMessage
              className="Site-StatusMessage"
              type={statusMessage.type}
              message={statusMessage.message}
            />
          )}
          <FormRender
            form={form}
            setForm={setForm}
            sections={[{ fields: ['name', 'email', 'phone', 'comment'] }]}
            errors={showErrors}
          />
        </div>
        <div className="ContactUsForm-Buttons">
          <Button
            title={'Надіслати'}
            theme="solid"
            fill="black"
            iconWeight="solid"
            size={48}
            onClick={onSubmit}
            isLoading={isLoading}
          />
        </div>
      </form>
    </div>
  )
}

class ContactForm {
  constructor() {
    this.name = {
      field: {
        fieldId: 'name',
        fieldType: 'input',
        inputType: 'text',
        label: "Ваше ім'я",
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.email = {
      field: {
        fieldId: 'email',
        fieldType: 'input',
        inputType: 'email',
        validationPattern: 'email',
        label: 'E-mail',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.phone = {
      field: {
        fieldId: 'phone',
        fieldType: 'phone',
        inputType: 'text',
        label: 'Контактный телефон',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.comment = {
      field: {
        fieldId: 'comment',
        fieldType: 'textarea',
        label: 'Коментар',
      },
      render: getFieldRenderObject(),
    }
  }
}

export default ContactUsForm
