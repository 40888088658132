import React, { useContext, useEffect, useState } from 'react'
import './Catalog.sass'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import getCollection from '../../utils/db/getCollection'
import Button from '../../ui/Button/Button'
import Spinner from '../../ui/Spinner/Spinner'
import { find, isEmpty, isNull, min, orderBy } from 'lodash'
import Icon from '../../ui/Icon/Icon'
import { Tooltip } from '@mui/material'
import locale from '../Top/LangChooser/locale/locale'
import { AuthContext } from '../../App'
import ClassFilter from './ClassFilter/ClassFilter'

function Catalog() {
  const [cars, setCars] = useState(null)
  const [carClass, setCarClass] = useState('all')
  const [displayedCars, setDisplayedCars] = useState(null)
  const { lang } = useContext(AuthContext)

  useEffect(() => {
    getCollection({
      path: 'cars',
      docIdName: 'carId',
      whereQueries: [{ fieldPath: 'isPublished', op: '==', value: true }],
    }).then((data) => {
      setCars(orderBy(data, ['title'], ['asc']))
    })
  }, [])

  useEffect(() => {
    if (!isEmpty(cars)) {
      if (carClass === 'all') setDisplayedCars(cars)
      else setDisplayedCars(cars.filter((c) => c.class === carClass))
    }
  }, [cars, carClass])

  return (
    <section id="catalog" name="catalog" className="Catalog">
      <div className="DefaultContainer">
        <SectionTitle
          title="Выберите авто"
          content={
            <ClassFilter activeClass={carClass} setClass={setCarClass} />
          }
        />
        <div className="Catalog-Container">
          {isNull(displayedCars) ? (
            <Spinner type="module" />
          ) : !isEmpty(displayedCars) ? (
            displayedCars.map((c) => <CarTile key={c.carId} {...c} />)
          ) : (
            <p className="EmptyCatalogMessage">{locale[lang].catalogEmpty}</p>
          )}
        </div>
      </div>
    </section>
  )
}

function CarTile(props) {
  const { lang } = useContext(AuthContext)
  const avatar = find(props.photos, ['isAvatar', true])

  return (
    <div className="CarTile">
      <div className="CarTags">
        <Tooltip title={locale[lang].tooltipPass} placement="right-start">
          <p className="Tag">
            <Icon name="user" weight="solid" />
            {props.passangers}
          </p>
        </Tooltip>
        {props.wd === '1' && (
          <Tooltip title={locale[lang].tooltipPriv} placement="right-start">
            <p className="Tag">
              <span>4WD</span>
            </p>
          </Tooltip>
        )}
        {props.mountains && (
          <Tooltip title={locale[lang].tooltipMount} placement="right-start">
            <p className="Tag">
              <Icon name="mountains" weight="solid" />
            </p>
          </Tooltip>
        )}
      </div>
      <img className="CarAvatar" src={avatar.publicUrl} alt="" />
      <h4 className="Title">
        {props.title} <span className="ModelYear">{props.modelYear}</span>
      </h4>
      <p className="Transmission">
        <span>КПП:</span>
        {props.price.map((p) => (
          <span
            key={`${props.carId}-${p.transmission}`}
            className="TransmissionType"
          >
            {p.transmission === 'A' ? 'Автомат' : 'Механика'}
          </span>
        ))}
      </p>
      <div className="PriceLine">
        <p>
          от{' '}
          <span className="Price">
            {min(props.price.map((p) => p.from30))} ₽
          </span>
          ./сутки
        </p>
        <Button
          theme="primary"
          title="Выбрать авто"
          type="navlink"
          path={`/book?carId=${props.carId}`}
          size={48}
        />
      </div>
    </div>
  )
}

export default Catalog
