import React, { useContext, useState } from 'react'
import './Ur.sass'
import { AuthContext } from '../../App'
import locale from '../../components/Top/LangChooser/locale/locale'
import Button from '../../ui/Button/Button'
import bgI from './bgi.jpg'
import getFieldRenderObject from '../../utils/newforms/render/getFieldRenderObject'
import { createForm } from '../../utils/newforms/createForm'
import StatusMessage, {
  useStatusMessage,
} from '../../ui/StatusMessage/StatusMessage'
import FormRender from '../../utils/newforms/render/FormRender'
import parse from 'html-react-parser'

function Tarifs() {
  const { lang } = useContext(AuthContext)
  const [form, setForm] = useState(
    createForm({ formPattern: new ContactForm(lang) })
  )
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()

  // const onSubmit = (e) => {
  //   e.preventDefault()
  //   if (!isFormValid({ form })) {
  //     setStatusMessage({
  //       show: true,
  //       type: 'fail',
  //       message: 'Будь ласка, заповніть усі поля форми',
  //       closeAfter: 5000,
  //     })
  //     return setShowErrors(true)
  //   }
  //   setShowErrors(false)
  //   setIsLoading(true)

  //   const values = getFormValues({ form })

  //   const func = functions.httpsCallable('emailSender')
  //   func({
  //     subject,
  //     to: 'iseeblog1@gmail.com',
  //     html: `
  //     Имя: ${values.name}<br/>
  //     E-mail: ${values.email}<br/>
  //     Номер телефона: ${values.phone.value}<br/>
  //     Комментарий: ${values.comment}<br/>
  //     ${additionalContent || ''}`,
  //   }).then(({ data }) => {
  //     if (data === 'success') {
  //       setStatusMessage({
  //         show: true,
  //         type: 'success',
  //         message: "Ми отримали ваш запит, очікуйте зв'язку з оператором",
  //         closeAfter: 5000,
  //       })
  //       setForm(createForm({ formPattern: new ContactForm() }))
  //       return setIsLoading(false)
  //     }
  //   })
  // }

  return (
    <div className="Ur">
      <div className="DefaultContainer">
        <div className="Ur-Container">
          <div className="STitle">{locale[lang].urTitle}</div>
          <div className="DataContainer">
            <div className="Text">
              {parse(locale[lang].urText)}
              {statusMessage.show && (
                <StatusMessage
                  className="Site-StatusMessage"
                  type={statusMessage.type}
                  message={statusMessage.message}
                />
              )}
              <FormRender
                form={form}
                setForm={setForm}
                sections={[
                  {
                    fields: ['org', 'email', 'phone', 'ts', 'days', 'comment'],
                  },
                ]}
                errors={showErrors}
              />
              <Button
                title={locale[lang].urFormOrder}
                type="navhashlink"
                icon="envelope"
                iconWeight="solid"
                path="/#catalog"
              />
              <div className="Notice">
                Отправляя заявку вы соглашаетесь с нашей{' '}
                <a href="/docs/policy.pdf" target="blank">
                  политикой политикой конфиденциальности и обработки
                  персональных данных
                </a>
              </div>
            </div>
            <img src={bgI} alt={locale[lang].urTitle} />
          </div>
        </div>
      </div>
    </div>
  )
}

class ContactForm {
  constructor(lang) {
    this.org = {
      field: {
        fieldId: 'org',
        fieldType: 'input',
        inputType: 'text',
        label: locale[lang].urFormName,
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.email = {
      field: {
        fieldId: 'email',
        fieldType: 'input',
        inputType: 'email',
        validationPattern: 'email',
        label: 'E-mail',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.phone = {
      field: {
        fieldId: 'phone',
        fieldType: 'phone',
        inputType: 'text',
        label: locale[lang].urFormPhone,
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.ts = {
      field: {
        fieldId: 'ts',
        fieldType: 'input',
        inputType: 'number',
        label: locale[lang].urFormTs,
      },
      render: getFieldRenderObject(),
    }
    this.days = {
      field: {
        fieldId: 'days',
        fieldType: 'input',
        inputType: 'number',
        label: locale[lang].urFormRent,
      },
      render: getFieldRenderObject(),
    }
    this.comment = {
      field: {
        fieldId: 'comment',
        fieldType: 'textarea',
        label: locale[lang].urFormDop,
      },
      render: getFieldRenderObject(),
    }
  }
}

export default Tarifs
