import React from 'react'
import './BookingTitle.sass'

function BookingTitle({ step, title }) {
  return (
    <div className="BookingTitle">
      {step && <div className="Circle">{step}</div>}
      <span>{title}</span>
    </div>
  )
}

export default BookingTitle
