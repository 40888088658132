import { isString, isArray, isEmpty } from 'lodash'
import uniqid from 'uniqid'
import { AsYouType } from 'libphonenumber-js/max'
import { checkValidity } from '../validation/validation'
import getFileExt from '../../files/getFileExt'

export const fieldChanged = ({ field, event }) => {
  const { value } = event.target

  if (field.fieldType === 'checkbox') {
    const { checked } = { ...event.target }
    field.value = checked
  } else if (field.fieldType === 'phone') {
    field.value = getPhoneNumberMetadata(value, event.country)
    field.country = event.country
  } else if (field.fieldType === 'inputFile') {
    field.config.inputLabel = event.target.files[0].name
    field.value = {
      file: event.target.files[0],
      fileName: uniqid('file-'),
      fileExt: getFileExt(event.target.files[0].name),
      publicUrl: null,
      storagePath: null,
      needUpload: true,
      created: new Date(),
    }
  } else if (field.fieldType === 'select' && field.config.multiOptions) {
    if (event.standAloneOption) {
      field.value = [value]
    } else {
      if (event.standAloneOptions) {
        let activeSAOption = false
        event.standAloneOptions.forEach((o) => {
          if (field.value.includes(o.value) && !activeSAOption) {
            activeSAOption = true
          }
        })
        if (activeSAOption) field.value = []
      }

      if (field.value.includes(value)) {
        field.value = field.value.filter((v) => v !== value)
      } else {
        field.value.push(value)
      }
    }
  } else {
    field.value = value
  }

  const { status, error } = checkValidity(field)
  field.valid = status
  field.error = error

  if (
    field.fieldType !== 'checkbox' &&
    field.fieldType !== 'inputFile' &&
    field.fieldType !== 'select' &&
    field.fieldType !== 'address' &&
    field.fieldType !== 'phone' &&
    field.fieldType !== 'dateRange' &&
    field.fieldType !== 'date'
  ) {
    field.value.trim() !== '' ? (field.touched = true) : (field.touched = false)
  }

  if (field.fieldType === 'phone') {
    field.value.value.trim() !== ''
      ? (field.touched = true)
      : (field.touched = false)
  }

  if (field.config.useDropValueButton) {
    if (
      (isString(field.value) && field.value !== '') ||
      (isArray(field.value) && !isEmpty(field.value))
    ) {
      field.config.icon = 'times'
      field.config.iconFunc = () => {}
      if (field.fieldType === 'select') {
        field.config.iconClass = 'SelectField-EditIcon'
      }
    } else {
      field.config.icon = ''
      field.config.iconFunc = ''
      field.config.iconClass = ''
    }
  }

  return field
}

const getPhoneNumberMetadata = (phone, country = null) => {
  if (phone) {
    const asYouType = new AsYouType(country || '')
    asYouType.input(phone)
    const phoneNumber = asYouType.getNumber()

    return {
      metadata: {
        country: phoneNumber ? phoneNumber.country || '' : '',
        formatInternational: phoneNumber
          ? phoneNumber.formatInternational() || ''
          : '',
        formatNational: phoneNumber ? phoneNumber.formatNational() || '' : '',
        number: phoneNumber ? phoneNumber.number || '' : '',
      },
      value: phone,
    }
  }
  return {
    metadata: {
      country: '',
      formatInternational: '',
      formatNational: '',
      number: '',
    },
    value: '',
  }
}
