import React, { useContext } from 'react'
import Icon from '../../../ui/Icon/Icon'
import { AuthContext } from '../../../App'
import './PhoneChooser.sass'
import { useRef } from 'react'
import { usePopUp } from '../../../hooks/usePopUp'
import EmailLink from '../EmailLink/EmailLink'
import Media from 'react-media'

function PhoneChooser() {
  const phoneRef = useRef()
  const ddRef = useRef()
  const [showPopUp, setShowPopUp] = usePopUp(phoneRef, ddRef)
  const phones = [
    '+7 (8152) 75-11-88',
    '+7 (902) 281-11-88',
    ' +7 (950) 892-55-55',
  ]

  const messIcons = (
    <>
      <a href="https://api.whatsapp.com/send/?phone=79022811188">
        <Icon name="whatsapp" weight="brand" className="Whatsapp" />
      </a>
      <a href="viber://chat?number=%2B79022811188">
        <Icon name="viber" weight="brand" className="Viber" />
      </a>
      <a href="https://t.me/%2B79022811188">
        <Icon name="telegram" weight="brand" className="Telegram" />
      </a>
    </>
  )

  return (
    <>
      <Media
        query="(max-width: 576px)"
        render={() => (
          <div className="MobilePhoneChooser">
            <div ref={phoneRef} className="PhoneSign">
              <Icon name="mobile" weight="regular" className="PhoneIcon" />
              <Icon
                name={showPopUp ? 'angle-up' : 'angle-down'}
                weight="regular"
              />
            </div>
            {showPopUp && (
              <div ref={ddRef} className="PhoneChooser-Container">
                {phones.map((p) => (
                  <a href={`tel:${p}`} className="PhoneChooser-Phone">
                    {p}
                  </a>
                ))}
                <div className="SocIcons">{messIcons}</div>
              </div>
            )}
          </div>
        )}
      />
      <Media
        query="(min-width: 577px)"
        render={() => (
          <div className="PhoneChooser">
            {messIcons}
            <Icon name="mobile" weight="regular" className="ChooserIcon" />
            <div ref={phoneRef} className="PhoneChooser-ActivePhone">
              {phones[0]}
            </div>
            <Icon
              name={showPopUp ? 'angle-up' : 'angle-down'}
              weight="regular"
            />
            {showPopUp && (
              <div ref={ddRef} className="PhoneChooser-Container">
                {phones.map((p) => (
                  <a href={`tel:${p}`} className="PhoneChooser-Phone">
                    {p}
                  </a>
                ))}
                <div className="SocIcons">{messIcons}</div>
              </div>
            )}
          </div>
        )}
      />
    </>
  )
}

export default PhoneChooser
