import React, { useEffect, useRef, useState } from 'react'
import Icon from '../../Icon/Icon'
import './DateRange.sass'
import InputMask from 'react-input-mask'
import DatePicker from '../../DatePicker/DatePicker'
import DateRangeOptionsPicker from './DateRangeOptionsPicker'
import { formatDateAsDayMonthYear } from '../../../utils/date/dateFormat'
import { convertDateFromString } from '../../../utils/date/convertDateFromString'
import { usePopUp } from '../../../hooks/usePopUp'
import { isEmpty } from 'lodash'

function DateRange({ fieldClass, lockIcon, iconClasses, ...props }) {
  const fieldRef = useRef()
  const ddbRef = useRef()
  const [showPopUp, setShowPopUp] = usePopUp(fieldRef, ddbRef)
  const [input, setInput] = useState(
    `${
      props.value.from
        ? formatDateAsDayMonthYear({ seconds: props.value.from / 1000 })
        : ''
    } - ${
      props.value.to
        ? formatDateAsDayMonthYear({ seconds: props.value.to / 1000 })
        : ''
    }`
  )

  if (input.match(/\d+/)) {
    fieldClass.push('Field_with_value')
  }

  const onInputChanged = (e) => {
    const { value } = e.target
    const dateRange = value.split('-')
    if (!isEmpty(dateRange)) {
      const valueToSet = { from: null, to: null }
      if (
        /^([0-9]){2}(\.)([0-9]){2}(\.)([0-9]){4}$/.test(dateRange[0].trim())
      ) {
        valueToSet.from = convertDateFromString(dateRange[0])
      }
      if (
        dateRange[1] &&
        /^([0-9]){2}(\.)([0-9]){2}(\.)([0-9]){4}$/.test(dateRange[1].trim())
      ) {
        valueToSet.to = convertDateFromString(dateRange[1])
      }
      props.changed({ target: { value: valueToSet } })
    }

    setInput(value)
  }

  useEffect(() => {
    setInput(
      `${
        props.value.from
          ? formatDateAsDayMonthYear({ seconds: props.value.from / 1000 })
          : ''
      } - ${
        props.value.to
          ? formatDateAsDayMonthYear({ seconds: props.value.to / 1000 })
          : ''
      }`
    )
  }, [props.value.from, props.value.to])

  return (
    <div className={fieldClass.join(' ')}>
      <span className="Field-Icon">
        <Icon name="calendar-alt" />
      </span>
      <InputMask
        mask="99.99.9999"
        maskChar={null}
        onChange={onInputChanged}
        value={input}
        disabled={props.isChangable === false}
      >
        {(inputProps) => (
          <input
            ref={fieldRef}
            {...inputProps}
            type={props.config.type}
            placeholder={props.config.placeholder}
          />
        )}
      </InputMask>
      {props.config.icon || lockIcon ? (
        <span
          onClick={
            props.config.useDropValueButton
              ? props.dropValue
              : props.config.iconFunc
          }
          className={iconClasses.join(' ')}
        >
          <Icon
            name={props.config.icon || lockIcon}
            size={props.config.iconSize && props.config.iconSize}
          />
        </span>
      ) : null}
      {props.config.marker ? (
        <span className="Field-Marker">
          <Icon name={props.config.marker} />
        </span>
      ) : null}
      <span className="Field-Bar" />
      <label>{props.config.label}</label>
      {props.showErrors && props.error ? (
        <div className="Field-Error">{props.error}</div>
      ) : null}
      {showPopUp && (
        <div ref={ddbRef} className="TaskDatePicker">
          {/* <DateRangeOptionsPicker
            pushDate={({ from, to }) =>
              props.changed({ target: { value: { from, to } } })
            }
          /> */}
          <div className="TaskDatePicker-Container">
            <DatePicker
              disabledKeyboardNavigation
              selected={props.value.from}
              startDate={props.value.from}
              endDate={props.value.to}
              minDate={props.value.from && props.value.from}
              // selectsRange
              onChange={([from, to]) =>
                props.changed({ target: { value: { from, to } } })
              }
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default DateRange
