import React, { useContext } from 'react'
import './AdditionalOptions.sass'
import BookingTitle from '../BookingTitle/BookingTitle'
import PickOption from '../../../../ui/PickOption/PickOption'
import { AuthContext } from '../../../../App'
import { orderBy } from 'lodash'

function AdditionalOptions({
  additionalOptions,
  pickedOptions,
  onAdditionalOptionClicked,
}) {
  const { lang } = useContext(AuthContext)
  return (
    <div className="AdditionalOptions">
      <BookingTitle title="Дополнительные опции" />
      <div className="AdditionalOptions-Container">
        {orderBy(additionalOptions, ['position'], ['asc']).map((o) => (
          <PickOption
            key={`option-${o.optionId}`}
            title={o[`title_${lang}`]}
            isActive={pickedOptions.includes(o.optionId)}
            description={o[`description_${lang}`]}
            onClick={() => onAdditionalOptionClicked(o.optionId)}
          />
        ))}
      </div>
    </div>
  )
}

export default AdditionalOptions
