import React, { useContext, useEffect, useRef } from 'react'
import './Nav.sass'
import Hamburger from 'react-hamburger-menu'
import Media from 'react-media'
import { withRouter } from 'react-router-dom'
import Logo from '../../ui/Logo/Logo'
import SiteNavLink from './SiteNavLink'
import { usePopUp } from '../../hooks/usePopUp'
import useScrollPosition from '../../hooks/useScrollPosition'
import { AuthContext } from '../../App'
import locale from '../Top/LangChooser/locale/locale'
import PhoneChooser from '../Top/PhoneChooser/PhoneChooser'
import EmailLink from '../Top/EmailLink/EmailLink'
import LangChooser from '../Top/LangChooser/LangChooser'

function Nav({ user, ...router }) {
  const hamRef = useRef()
  const navRef = useRef()
  const [menuIsOpen, setMenuIsOpen] = usePopUp(hamRef, navRef)
  const pos = useScrollPosition()
  const { lang } = useContext(AuthContext)

  useEffect(() => {
    setMenuIsOpen(false)
  }, [router.location.pathname, router.location.hash])

  return (
    <nav
      id="nav"
      className={[
        'Site-Nav',
        ...(pos > 0 ? ['Site-Nav_theme_shadow'] : []),
      ].join(' ')}
    >
      <div className="Nav-Container DefaultContainer">
        <Media
          query="(max-width: 1200px)"
          render={() => (
            <span ref={hamRef}>
              <Hamburger
                isOpen={menuIsOpen}
                width={24}
                height={16}
                strokeWidth={2}
                className="Site-Hamburger-Container"
                color="#303030"
                borderRadius={2}
                animationDuration={0.2}
              />
            </span>
          )}
        />
        <Logo navLink path="/#home" />
        <Media query="(max-width: 576px)" render={() => <PhoneChooser />} />

        <div
          ref={navRef}
          className="Site-Nav-Menu"
          style={menuIsOpen ? { display: 'grid' } : {}}
        >
          <Media
            query="(max-width: 576px)"
            render={() => (
              <>
                <LangChooser />
              </>
            )}
          ></Media>
          <SiteNavLink title={locale[lang].nlinkRent} path="/#catalog" />
          <SiteNavLink title={locale[lang].nlinkBenefits} path="/#benefits" />
          <SiteNavLink title={locale[lang].nlinkRules} path="/#client" />
          <SiteNavLink title={locale[lang].nlinkTarifs} path="/tarifs" />
          <SiteNavLink title={locale[lang].nlinkUr} path="/ur" />
          <SiteNavLink title={locale[lang].nlinkWhere} path="/where-to" />
          <SiteNavLink title={locale[lang].nlinkAbout} path="/#about" />
          <SiteNavLink title={locale[lang].nlinkContacts} path="/#contacts" />
        </div>
      </div>
    </nav>
  )
}

// const mapStateToProps = (state) => ({ user: state.auth.user })

export default withRouter(Nav)
