import React from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../App'
import locale from '../../components/Top/LangChooser/locale/locale'
import Button from '../../ui/Button/Button'
import './WhereTo.sass'
import i1 from './assets/terib.png'
import i2 from './assets/sever.png'
import i3 from './assets/hibiny.jpg'
import i4 from './assets/ter.jpg'
import i5 from './assets/seid.jpg'
import parse from 'html-react-parser'

function WhereTo() {
  const { lang } = useContext(AuthContext)
  const data = [
    {
      img: i1,
      title: locale[lang].where2TeribTitle,
      text: locale[lang].where2TeribText,
    },
    {
      img: i2,
      title: locale[lang].where2SeverTitle,
      text: locale[lang].where2SeverText,
    },
    {
      img: i3,
      title: locale[lang].where2HibTitle,
      text: locale[lang].where2HibText,
    },
    {
      img: i4,
      title: locale[lang].where2TerTitle,
      text: locale[lang].where2TerText,
    },
    {
      img: i5,
      title: locale[lang].where2SeidTitle,
      text: locale[lang].where2SeidText,
    },
  ]

  return (
    <section class="WhereTo">
      <div className="DefaultContainer">
        {data.map((d, i) => (
          <>
            <h4 className="STitle">{d.title}</h4>
            <div className={`DataContainer ${i % 2 !== 0 && 'RightImage'}`}>
              <img src={d.img} alt={d.title} />
              <div className="Text">
                {parse(d.text)}
                <Button
                  title="Арендовать авто"
                  type="navhashlink"
                  icon="cars"
                  iconWeight="solid"
                  path="/#catalog"
                />
              </div>
            </div>
          </>
        ))}
      </div>
    </section>
  )
}

export default WhereTo
