import React from 'react'
import './SectionTitle.sass'

function SectionTitle({ title, theme = null, content }) {
  return (
    <div className="SectionTitle-Container">
      <h5 className={`SectionTitle ${theme ? 'SectionTitle_theme_white' : ''}`}>
        {title}
      </h5>
      {content && content}
    </div>
  )
}

export default SectionTitle
