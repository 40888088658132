import React from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../App'
import Button from '../../ui/Button/Button'
import ContactUsPopUp from '../ContactUs/ContactUsPopUp'
import './Top.sass'
import { NavLink } from 'react-router-dom'
import EmailLink from './EmailLink/EmailLink'
import PhoneChooser from './PhoneChooser/PhoneChooser'
import LangChooser from './LangChooser/LangChooser'

function Top() {
  const { showContactUs, setShowContactUs } = useContext(AuthContext)

  return (
    <div className="Top">
      {showContactUs.show && <ContactUsPopUp />}
      <div className="DefaultContainer">
        <div className="Top-Container">
          <EmailLink />
          <PhoneChooser />
          <LangChooser />
        </div>
      </div>
    </div>
  )
}

export default Top
