import React from 'react'
import './DatePicker.sass'
import Picker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ru from 'date-fns/locale/ru'

const DatePicker = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className="DatePicker">
      <Picker {...props} locale={ru} inline />
    </div>
  )
})

export default DatePicker
