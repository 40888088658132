import React from 'react'
import Icon from '../../../ui/Icon/Icon'
import './EmailLink.sass'
import { useContext } from 'react'
import { AuthContext } from '../../../App'

function EmailLink() {
  return (
    <div className="EmailLink">
      <a href={`mailto:autoprokat51@mail.ru`} className="EmailIcon">
        <Icon name="envelope" weight="regular" />
      </a>
      <div className="Content">
        <a href={`mailto:autoprokat51@mail.ru`} className="Email">
          autoprokat51@mail.ru
        </a>
        {/* <span className="Description">Напишите нам</span> */}
      </div>
    </div>
  )
}

export default EmailLink
