import React, { useEffect, useRef, useState } from 'react'
import Icon from '../../Icon/Icon'
import './Date.sass'
import InputMask from 'react-input-mask'
import DatePicker from '../../DatePicker/DatePicker'
import DateRangeOptionsPicker from './DateRangeOptionsPicker'
import { formatDateAsDayMonthYear } from '../../../utils/date/dateFormat'
import { convertDateFromString } from '../../../utils/date/convertDateFromString'
import { usePopUp } from '../../../hooks/usePopUp'
import { isEmpty } from 'lodash'

function Date({ fieldClass, lockIcon, iconClasses, ...props }) {
  const fieldRef = useRef()
  const ddbRef = useRef()
  const [showPopUp, setShowPopUp] = usePopUp(fieldRef, ddbRef)
  const [input, setInput] = useState(
    props.value ? formatDateAsDayMonthYear({ seconds: props.value / 1000 }) : ''
  )

  if (input.match(/\d+/)) {
    fieldClass.push('Field_with_value')
  }

  const onInputChanged = (e) => {
    const { value } = e.target

    if (/^([0-9]){2}(\.)([0-9]){2}(\.)([0-9]){4}$/.test(value.trim())) {
      props.changed({ target: { value: convertDateFromString(value) } })
    }

    setInput(value)
  }

  useEffect(() => {
    setInput(
      props.value
        ? formatDateAsDayMonthYear({ seconds: props.value / 1000 })
        : ''
    )
  }, [props.value])

  return (
    <div className={fieldClass.join(' ')}>
      <span className="Field-Icon">
        <Icon name="calendar-alt" />
      </span>
      <InputMask
        mask="99.99.9999"
        maskChar={null}
        onChange={onInputChanged}
        value={input}
        disabled={props.isChangable === false}
      >
        {(inputProps) => (
          <input
            ref={fieldRef}
            {...inputProps}
            type={props.config.type}
            placeholder={props.config.placeholder}
          />
        )}
      </InputMask>
      {props.config.icon || lockIcon ? (
        <span
          onClick={
            props.config.useDropValueButton
              ? props.dropValue
              : props.config.iconFunc
          }
          className={iconClasses.join(' ')}
        >
          <Icon
            name={props.config.icon || lockIcon}
            size={props.config.iconSize && props.config.iconSize}
          />
        </span>
      ) : null}
      {props.config.marker ? (
        <span className="Field-Marker">
          <Icon name={props.config.marker} weight="solid" />
        </span>
      ) : null}
      <span className="Field-Bar" />
      <label>{props.config.label}</label>
      {props.showErrors && props.error ? (
        <div className="Field-Error">{props.error}</div>
      ) : null}
      {showPopUp && (
        <div ref={ddbRef} className="DatePicker">
          <DatePicker
            disabledKeyboardNavigation
            selected={props.value}
            onChange={(date) => {
              props.changed({ target: { value: date } })
              setShowPopUp(false)
            }}
          />
        </div>
      )}
    </div>
  )
}

export default Date
