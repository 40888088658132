import React, { forwardRef } from 'react'
import BookingTitle from '../BookingTitle/BookingTitle'
import PickOption from '../../../../ui/PickOption/PickOption'
import './PaymentMethods.sass'
import StatusMessage from '../../../../ui/StatusMessage/StatusMessage'

const PaymentMethods = forwardRef(function (
  { statusMessage, paymentType, onPaymentClicked },
  ref
) {
  return (
    <div ref={ref} className="PaymentMethods">
      <BookingTitle step={5} title={'Выберите способ оплаты'} />
      <div className="PaymentMethods-Container">
        {statusMessage.show && statusMessage.section === 'payment' && (
          <StatusMessage
            className="Site-StatusMessage"
            type={statusMessage.type}
            message={statusMessage.message}
          />
        )}
        <PickOption
          isActive={paymentType === 'card'}
          onClick={() => onPaymentClicked('card')}
          title={'Online оплата банк. картой'}
        />
        <PickOption
          isActive={paymentType === 'cash'}
          onClick={() => onPaymentClicked('cash')}
          title={'Оплата при получение авто'}
        />
      </div>
    </div>
  )
})

export default PaymentMethods
