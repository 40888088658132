import React, { useState } from 'react'
import check from './check.png'
import Spinner from '../../ui/Spinner/Spinner'
import { find, isEmpty } from 'lodash'
import './Success.sass'
import { useEffect } from 'react'
import getDoc from '../../utils/db/getDoc'
import { getUrlQueryParams } from '../../utils/queries/getUrlQueryParams'
import { withRouter } from 'react-router-dom'
import Button from '../../ui/Button/Button'

function Success({ ...router }) {
  const searchString = router.location.search
  const { carId } = getUrlQueryParams({
    searchString,
    params: ['carId', 'transmission'],
  })

  const [carData, setCarData] = useState(null)
  useEffect(() => {
    getDoc({ path: 'cars', docId: carId, docIdName: 'carId' }).then((data) =>
      setCarData(data)
    )
  }, [])

  const avatar = !isEmpty(carData)
    ? find(carData.photos, ['isAvatar', true])
    : null

  return (
    <div className="Success">
      <div className="DefaultContainer">
        <div className="Success-Container">
          {!isEmpty(carData) ? (
            <>
              <p className="Title">Ваш заказ успешно оформлен!</p>
              <p className="Description">
                Ожидайте подтверждения Вашей заявки по E-mail
              </p>
              <div className="Image-Container">
                <img src={check} alt="Success" />
                <img
                  className="CarImage"
                  src={avatar.publicUrl}
                  alt={carData.title}
                />
              </div>
              <Button
                theme="primary"
                title="Вернуться на главную"
                type="navhashlink"
                path="/#home"
                size={48}
              />
            </>
          ) : (
            <Spinner type="module" />
          )}
        </div>
      </div>
    </div>
  )
}

export default withRouter(Success)
