import React, { forwardRef } from 'react'
import BookingTitle from '../BookingTitle/BookingTitle'
import './UserForm.sass'
import FormRender from '../../../../utils/newforms/render/FormRender'
import StatusMessage from '../../../../ui/StatusMessage/StatusMessage'

const UserForm = forwardRef(function (
  { statusMessage, form, setForm, showErrors },
  ref
) {
  return (
    <div ref={ref} className="UserForm">
      <BookingTitle step={4} title={'Заполните форму бронирования'} />
      <div className="UserForm-Container">
        {statusMessage.show && statusMessage.section === 'form' && (
          <StatusMessage
            className="Site-StatusMessage"
            type={statusMessage.type}
            message={statusMessage.message}
          />
        )}
        <p className="SubTitle">Ваши данные</p>
        <FormRender
          form={form}
          setForm={setForm}
          sections={[
            {
              fields: [
                'userFio',
                'userBdate',
                'userEmail',
                'userPhone',
                'userFact',
                'userComment',
              ],
            },
          ]}
          errors={showErrors}
        />
        <p className="SubTitle">Паспорт</p>
        <FormRender
          form={form}
          setForm={setForm}
          sections={[
            {
              fields: [
                'passportSeria',
                'passportDate',
                'passportKem',
                'passportAddress',
              ],
            },
          ]}
          errors={showErrors}
        />
        <p className="SubTitle">Вод. удостоверение</p>
        <FormRender
          form={form}
          setForm={setForm}
          sections={[
            {
              fields: ['vodSeria', 'vodStage'],
            },
          ]}
          errors={showErrors}
        />
      </div>
    </div>
  )
})

export default UserForm
