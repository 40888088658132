import React from 'react'
import './TotalsBlock.sass'
import { toNumber } from 'lodash'
import Button from '../../../../ui/Button/Button'

function TotalsBlock({ counters, paymentType, onSubmit, isLoading }) {
  const totals = {
    totalRentPrice: counters.fullDays * counters.rentPrice,
    delAndRetPrice: toNumber(counters.delivery) + toNumber(counters.return),
    totalPrice:
      counters.fullDays * counters.rentPrice +
      toNumber(counters.delivery) +
      toNumber(counters.return) +
      toNumber(counters.carwash) +
      toNumber(counters.options) +
      toNumber(counters.zalog),
  }

  return (
    <div className="TotalsBlock">
      <p className="Title">Предварительная стоимость аренды</p>
      <p>
        Срок аренды (в сутках):{' '}
        <span>{counters.fullDays ? counters.fullDays : 'Не определено'}</span>
      </p>
      <p>
        Тариф (₽/сутки):{' '}
        <span>
          {counters.rentPrice ? `${counters.rentPrice} ₽` : 'Не определено'}
        </span>
      </p>
      <p>
        Стоимость аренды авто:{' '}
        <span>
          {totals.totalRentPrice
            ? `${totals.totalRentPrice} ₽`
            : 'Не определено'}
        </span>
      </p>
      <p>
        Залог за авто: <span>{counters.zalog} ₽</span>
      </p>
      <p>
        Стоимость доставки/ возврата авто:{' '}
        <span>{totals.delAndRetPrice} ₽</span>
      </p>
      <p>
        Стоимость мойки авто: <span>{counters.carwash} ₽</span>
      </p>
      <p>
        Дополнительные опции: <span>{counters.options} ₽</span>
      </p>
      <p>
        Предпочитаемый способ оплаты:{' '}
        <span>
          {paymentType
            ? paymentType === 'card'
              ? 'Онлайн оплата банк. картой'
              : 'Оплата при получение авто'
            : 'Не определено'}
        </span>
      </p>
      <p>
        Итоговая стоимость аренды с учётом залога:{' '}
        <span>{totals.totalPrice} ₽</span>
      </p>

      <Button
        theme="primary"
        title="Арендовать авто"
        type="navhashlink"
        path="#catalog"
        size={48}
        onClick={onSubmit}
        isLoading={isLoading}
      />
      <div className="Notice">
        Отправляя заявку вы соглашаетесь с нашей{' '}
        <a href="/docs/policy.pdf" target="blank">
          политикой политикой конфиденциальности и обработки персональных данных
        </a>
      </div>
    </div>
  )
}

export default TotalsBlock
