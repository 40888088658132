import React from 'react'
import './LangChooser.sass'
import ru from './assets/ru.svg'
import en from './assets/en.svg'
import cn from './assets/cn.svg'

function LangChooser() {
  return (
    <div className="LangChooser">
      <span onClick="ChooseLang('ru');" class="LangElement">
        <img src={ru} alt="RU" />
        RU
      </span>
      <span onClick="ChooseLang('ru');" class="LangElement">
        <img src={en} alt="EN" />
        EN
      </span>
      <span onClick="ChooseLang('ru');" class="LangElement">
        <img src={cn} alt="CN" />
        CN
      </span>
    </div>
  )
}

export default LangChooser
