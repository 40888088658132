import React from 'react'
import Icon from '../Icon/Icon'
import './PickOption.sass'

function PickOption({
  isActive,
  title,
  description,
  useAdditionalInfo,
  busyPrice,
  otherPrice,
  onClick,
}) {
  return (
    <div
      className={`PickOption ${isActive && 'PickOption_status_active'}`}
      style={useAdditionalInfo ? { gridTemplateColumns: '36px 1fr 300px' } : {}}
      onClick={onClick}
    >
      <div className="Circle">
        <Icon name="check" weight="solid" />
      </div>
      <div className="Data">
        <p className="Title">{title}</p>
        {description && <p className="Description">{description}</p>}
      </div>
      {useAdditionalInfo && (
        <div className="AdditionalInfo">
          <p className="SubTitle">Стоимость</p>
          <p className="PriceLine">
            Ежедневно с 09:30 до 20:30{' '}
            <span className="Price">- {busyPrice} ₽</span>
          </p>
          <p className="PriceLine">
            Вне этого времени <span className="Price">- {otherPrice} ₽</span>
          </p>
        </div>
      )}
    </div>
  )
}

export default PickOption
